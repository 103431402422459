import React, { Fragment } from 'react';
import Footer from './footer';




function Index() {
  return (
    <Fragment>
     



  <header id="header" class="fixed-top " style={{ overflow: "hidden;"}}>
    <div class="container-fluid">

      <div class="row justify-content-center">
        <div class="col-xl-9 d-flex align-items-center justify-content-lg-between pt-4">
        	 <a href="/" class="logo me-auto me-lg-0 ">
            <img src="assets/img/osmart 1.svg" alt="" class="img-fluid" width="300px" /></a>
         <h4 class="logo me-auto me-lg-9" ></h4>
         
      
          <nav id="navbar" class="navbar order-last order-lg-0">
            <ul> 

   
              <li><a class="nav-link scrollto" href="/">Accueil</a></li>
              <li><a class="nav-link scrollto" href="/reparations">Réparation</a></li>
              <li><a class="nav-link scrollto" href="/contact">Contact</a></li>
             
            </ul>
      
          </nav>
          
        </div>
      </div>

    </div>
  </header>

<section id="header1-responsive">

  <header class="navbar-sticky bg-light"><nav class="navbar navbar-expand-lg navbar-light"><div class="container-fluid">
    <button class="navbar-toggler order-3" type="button" data-bs-toggle="modal" data-bs-target="#navigation" aria-label="Ouvrir le menu">
        <span class="navbar-toggler-icon"></span></button><a class="navbar-brand order-2 order-lg-1" href="/" aria-label="Retourner à l'accueil">
           
        <img src="assets/img/osmart 1 (1).svg" alt="" srcset="" />


            </a><div class="collapse navbar-collapse order-lg-2">
            <ul class="navbar-nav ms-auto" role="list">
            <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="/" aria-label="Réparez dés maintenant votre appareil"><span class="fw-medium">Accueil</span></a>
            </li>
            <li class="nav-item"><a class="nav-link" href="/reparations" aria-label="Découvrez les accessoires WeFix"><span class="fw-medium">Réparation</span></a></li>
            <li class="nav-item"><a class="nav-link" href="/contact" aria-label="Toutes nos boutiques WeFix"><span class="fw-medium">Contact</span></a></li>
           </ul>
           </div></div></nav></header>


           <div class="wf-aside modal" tabindex="-1" id="navigation" role="dialog" x-data="{ reparation: false, protection: false }"><div class="wf-aside__wrap modal-dialog modal-dialog-scrollable modal-fullscreen" role="document">
                <div class="wf-aside__screen modal-content" x-show="!reparation &amp;&amp; !protection" x-cloak><div class="wf-aside__screen-nav">
                    <div class="wf-aside__screen-nav--title">Menu</div>
                    <div class="wf-aside__screen-nav-close" data-bs-dismiss="modal" aria-label="Fermer le menu"><i class="ci-close"></i></div></div>
          <a href="/"> <div class="wf-aside__protection-content-card-body--title">Accueil</div></a> 
          <a href="/reparations"> <div class="wf-aside__protection-content-card-body--title">Réparation</div></a>
            <a href="/contact"> <div class="wf-aside__protection-content-card-body--title">Contact</div></a>
        </div>
     </div></div>
     </section>

  




  <section id="hero" class="d-flex justify-content-center">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-10 ">
          <h1>Quel appareil <br />
            voulez-vous réparer ?</h1>
            
          <a href="/reparations" class="get-started-btn scrollto">Demande de devis</a>
        </div>
         
      </div>
    </div>
  </section>

  <div className="row mt-5" style={{ marginTop: "3%" }}>
      <div className="column1">
        <img src="assets/img/Group 78.svg" alt="" srcSet="" />
      </div>
      <div className="column2">
        

        <div>
          <span className="get-started-btn-1 ">
          Nos services
          </span>
        </div>
        <br />
        <br />
        <br /><br />
        <p>
          <h1 >
            Smartphones, <br />
            Tablettes, ou encore<br />
            Nintendo Switch
          </h1>
        </p>
        <br />
        <br />
        <p >
          La promesse O'smart, c'est une réparation rapide et sécurisée.
        </p>
        <br />
        <p>
          <i style={{ fontWeight: "600" }}>Réparer, accessoiriser, ou protéger vos appareils est notre mission chez O'smart.</i>
        </p>
      </div>
    </div>

    <section class="text-center pos-r" style={{ marginTop: "3%",marginBottom:"3%" }}>
    <div class="container">
      
      <div class="row">
        <div id="svg-container">
          
        </div>
        <div class="col-lg-3 col-md-12">
          <div class="work-process">
          <span className="line-1">  <img src="assets/img/Line 1.svg" alt="" srcset="" width="" /></span>
            <div class="step-num-box"> 
            
              <div class="step-icon"><span><img src="assets/img/Mask group.svg" alt="" srcset="" width="40%" /></span>
              </div>
              <div class="step-"></div>
            </div>
            <div class="step-desc">
          
              <p class="steps-five">Je prends RDV en ligne <br /> <span style={{ fontWeight: "600" }}> www.osmart72.fr</span></p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 md-mt-5">
          <div class="work-process">
          <span className="line-1">  <img src="assets/img/Line 1.svg" alt="" srcset="" width="" /></span>
            <div class="step-num-box">
              <div class="step-icon"><span><img src="assets/img/Mask group (1).svg" alt="" srcset="" width="40%" /></span>
              </div>
            
            </div>
            <div class="step-desc">
             
              <p class="steps-five">à mon domicile ou dans <br />  l’une de nos boutiques </p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 md-mt-5">
          <div class="work-process">
          <span className="line-1">  <img src="assets/img/Line 1.svg" alt="" srcset="" width="" /></span>
            <div class="step-num-box">
             
              <div class="step-icon"><span><img src="assets/img/Mask group (2).svg" alt="" srcset="" width="40%" /></span>
              </div>
             
            </div>
            <div class="step-desc">
            
              <p class="steps-five">Un coursier récupère mon appareil <br/> ou je me déplace en boutique</p>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-12 md-mt-5">
          <div class="work-process">
            <div class="step-num-box">
              <div class="step-icon"><span><img src="assets/img/selfie 1.svg" alt="" srcset="" width="40%" /></span>
              </div>
          
            </div>
            <div class="step-desc">
            
              <p class="steps-five">Je prends un selfie le jour même</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
<div class="row mt-5" >
  <div class="column3" > <div><span  class="get-started-btn-1 ">Nos valeurs</span> </div>
 <br/><br/><br/><br/>
  
  <p>
    <h1 >C’est toute une histoire</h1>  
  </p><br/><br/><br />
  <p >O'smart, entreprise de réparation de produits électroniques depuis + de 3 ans. Nos experts certifiés nous permettent de délivrer une prestation de qualité, et un suivi personnalisé pour nos clients. Nous n'attendons qu'une chose : continuer à grandir et performer grâce à l'efficacité, l'implication et le sens de service inné de nos collaborateurs !</p>

  </div>

  <div class="column4"> <img src="assets/img/Group 8.svg" alt="" srcset="" /></div></div>


 

  <div class="row mt-5" style={{ marginTop: "1%" }}>
  <div class="column5"> <img src="assets/img/Group 80.svg" alt="" srcset="" /></div>
  <div class="column6" > <div><span  class="get-started-btn-1">Appareil neuf</span> </div>
 <br/><br/><br/><br/>
  
  <p>
    <h1 >Récupérez votre appareil<br/>
       neuf après seulement <br/>1 heure !</h1>  
  </p><br/><br/>
  <p >
  Nous garantissons nos réparations 1 an à compter de la restitution de votre appareil après la prestation de nos experts.
  </p>

  <p style={{ fontWeight: "600" }}><i>
    *La garantie est de 3 mois pour le changement de batterie.</i></p>

  </div>

 </div>


 <div class="row mt-5"  id="les-plus" >
 <div  style={{ float: "inherit !important" }}><span  class="get-started-btn-3 mt-5" >Notre +</span> </div>
     <h1  style={{ marginTop: "3%" }} >Les +</h1></div>


 <section class="container" id="marque-plus"><div class="grid " style={{"--wf-gap": "16px", marginTop: "3%",marginRight:"-24%"}}>
  {/* <div class="g-col md-2 g-col-2"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
      <img src="assets/img/marque.svg" alt="" srcset="" /></div></div><div class="title-subsection mt-1">Une marque</div><p class="mt-3">du groupe Fnac Darty
   
  </p></div></div></div>*/}
      <div class="g-col md-2 g-col-2 "><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/réparation-express.svg" alt="" srcset="" />
      </div></div><div class="title-subsection mt-1">Préparation express </div><p class="mt-3">avec ou sans rendez-vous
       
        </p></div></div></div>


        <div class="g-col md-2 g-col-2"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
          <img src="assets/img/Experts.svg" alt="" srcset="" />
        </div></div><div class="title-subsection mt-1">Experts <img src="assets/img/osmart 1 (1).svg" alt="" srcset="" width={"50%"}/></div><p class="mt-3">Certifiés
          </p></div></div></div>

       <div class="g-col md-2 g-col-2"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block  translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/garantie.svg" alt="" srcset="" />
       </div></div><div class="title-subsection mt-1">Garantie réparation de 1 an</div><p class="mt-3">hors sur les batteries qui sont garanties 3 mois
   
     </p></div></div></div>

      <div class="g-col md-2 g-col-2"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/origine.svg" alt="" srcset="" />
      </div></div><div class="title-subsection mt-1">Pièces d’origine</div><p class="mt-3">constructeur
    
      </p></div></div></div>


</div></section>


<section class="container" id="marque-moins"><div class="grid " style={{"--wf-gap": "16px", marginTop: "3%"}}>
    {/*<div class="g-col md-12 g-col-12"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
      <img src="assets/img/marque.svg" alt="" srcset="" /></div></div><div class="title-subsection mt-1">Une marque</div><p class="mt-3">du groupe Fnac Darty
   
</p></div></div></div> */}

      <div class="g-col md-12 g-col-12 "><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/réparation-express.svg" alt="" srcset="" />
      </div></div><div class="title-subsection mt-1">Préparation express </div><p class="mt-3">avec ou sans rendez-vous
       
        </p></div></div></div>


        <div class="g-col md-12 g-col-12"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
          <img src="assets/img/Experts.svg" alt="" srcset="" />
        </div></div><div class="title-subsection mt-1">Experts <img src="assets/img/osmart 1 (1).svg" alt="" srcset="" width={"50%"}/></div><p class="mt-3">Certifiés
          </p></div></div></div>

       <div class="g-col md-12 g-col-12"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block  translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/garantie.svg" alt="" srcset="" />
       </div></div><div class="title-subsection mt-1">Garantie réparation de 1 an</div><p class="mt-3">hors sur les batteries qui sont garanties 3 mois
   
     </p></div></div></div>

      <div class="g-col md-12 g-col-12"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body"><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/origine.svg" alt="" srcset="" />
      </div></div><div class="title-subsection mt-1">Pièces d’origine</div><p class="mt-3">constructeur
    
      </p></div></div></div>


</div></section>




<div className="row mt-5" style={{padding: '6% 6% 0% 6%', backgroundColor: '#D6F2FF', margin: '0% 04% 0% 04%', borderRadius: '15px'}}>
  <div className="column7"> 
    <iframe class="style-map" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11830.04440978669!2d0.15703223002764538!3d47.97045715854553!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e28517f8965aed%3A0x8a326b4f0d338169!2zT-KAmVNtYXJ0!5e0!3m2!1sfr!2sma!4v1680773983058!5m2!1sfr!2sma"   allowfullscreen="" style={{ borderRadius: '15px'}} loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  </div>
  <div className="column8">
    <p>Récupérez votre Appareil comme neuf directement en Boutique, ou en choisissant l'option de Livraison à Domicile.
      Notre Solution se veut respectueuse de l'Environnement (choix de matériaux qualitatifs)</p>
    <br />
    <p style={{ fontWeight: "600" }}><i>
      O'smart s'engage à respecter 100% des délais de réparation et livraison, conformément à notre politique clientèle.</i></p>
    <img src="assets/img/phone.svg" alt="" srcSet="" style={{paddingLeft: '40%'}} />
  </div>
</div>


<div class="row mt-5"  id="les-plus" >
 <div style={{ float: "inherit !important" }}><span  class="get-started-btn-3 mt-5" >Notre solution</span> </div>
     <h1  style={{ marginTop: "2%" }} >Une solution clé en main</h1></div>


<section id="cd-timeline" class="cd-container">
		<div class="cd-timeline-block">
			<div class="cd-timeline-img cd-picture">
				<img src="assets/img/paper 1.svg" alt="Picture" />
			</div> 

			<div class="cd-timeline-content">
				<h2>Etape 1 : Définition de vos besoins</h2>
				<p>Analyse de votre panne en boutique o'smart ou visite du site web</p>
			
			</div> 
		</div>

		<div class="cd-timeline-block">
			<div class="cd-timeline-img cd-movie">
				<img src="assets/img/contract 1.svg" />
			</div> 

			<div class="cd-timeline-content">
				<h2>Etape 2 : Devis gratuit & sans engagement</h2>
				<p>Formalisez votre projet avec un expert, édition d'un devis et planification de votre réparation en boutique ou dans la rubrique "réparation" de notre site internet</p>
	
			</div> 
		</div> 

		<div class="cd-timeline-block">
			<div class="cd-timeline-img cd-picture">
				<img src="assets/img/repair 1.svg" alt="Picture" />
			</div> 

			<div class="cd-timeline-content">
				<h2>Etape 3 : Réparation</h2>
				<p>Réparation express de votre appareil</p>

			</div> 
		</div> 

		<div class="cd-timeline-block">
			<div class="cd-timeline-img cd-location">
				<img src="assets/img/fast-delivery 1.svg" alt="Location" />
			</div> 

			<div class="cd-timeline-content">
				<h2>Etape 4</h2>
				<p>livraison à domicile ou récupération en boutique o'smart</p>

			</div> 
		</div> 

	

	</section> 

  <div class="g-col-md-12 g-col-12 text-center"><img src="assets/img/Group 79 (1).svg" alt="" srcset="" class="image-osmart"  />
  </div>
  <div class="g-col-md-12 g-col-12 text-center"><a href="/contact" class="get-started-btn-2 ">Contactez-nous</a></div>


        <Footer />
    </Fragment>


  );
}

export default Index;
