import React, { Fragment } from 'react';
import Footer from './footer';
import Header from './header';
import Slide from './slider';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Reparation() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    };
  return (
    <Fragment>
       <Header />

      <Slide />

  
<br/><br/><br/>


<section className="container mt-3">
      <div className="wf-step" aria-label="stepper" role="navigation">
        <a className="wf-step__item wf-step__item--active "  aria-label="Choix appareil">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
        </a>
        <a className="wf-step__item wf-step__item--hover"  aria-label="Marque">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Marque</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label="Modèle<">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Modèle</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label=">Panne">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Panne</div>
        </a>
        <a className="wf-step__item  wf-step__item--hover" aria-label="BCoordonnées">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
        </a>
      </div>
    </section>



    <section id="reparation-1" class="container mt-5"><div class="grid mt-5"  style={{ "--wf-gap": "16px", marginLeft: "8%" }}>


    <div class=" g-col-3 mt-5">
    <div id="border-style" class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"  >
        <div class="card-body"><div class="d-inline-block "><div class="title-subsection text-light">
    <img src="assets/img/smartphone.png" alt="" srcset="" style={{ width: "76%" }}/>
    <img class="style-appareil-1" src="assets/img/my-color-1.svg" alt="" srcset="" style={{ }}/>
  </div></div><div class="title-subsection mt-1" style={{ paddingTop: "18%" }}>
<a href='/smartphone'><button class="btn btn--2" type="submit"  >Smartphones</button></a>  
</div></div></div></div>

  <div class=" g-col-3 mt-5">
    <div id="border-style" class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"  >
        <div class="card-body"><div class="d-inline-block "><div class="title-subsection text-light">
    <img src="assets/img/tablette.png" alt="" srcset="" />
    <img src="assets/img/my-color.svg" alt="" srcset="" style={{ position: "absolute", paddingRight: "0.4%", paddingLeft: "0.1%", zIndex: "-1", transform: "translateX(-89.1%)", marginTop: "74.6%" }}/>
  </div></div><div class="title-subsection mt-1" style={{ paddingTop: "35%" }}>
  <a href='/tablette'> <button class="btn btn--3" type="submit"  >Tablettes</button></a> 
</div></div></div></div>


<div class=" g-col-3 mt-5">
    <div id="border-style" class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"  >
        <div class="card-body"><div class="d-inline-block "><div class="title-subsection text-light">
    <img src="assets/img/console.png" alt="" srcset="" />
    <img src="assets/img/my-color-2.svg" alt="" srcset="" style={{ position: "absolute", paddingRight: "0.4%", paddingLeft: "0.1%", zIndex: "-1", transform: "translateX(-89.1%)", marginTop: "74.6%" }}/>
  </div></div><div class="title-subsection mt-1" style={{ paddingTop: "18%" }}>
  <a href='/console'>    <button class="btn btn--4" type="submit"  >Consoles de jeu</button></a>
</div></div></div></div></div>


 </section>

 <section id="reparation-2" class="container mt-5"><div class="grid mt-5"  style={{ "--wf-gap": "16px", marginLeft: "8%" }}>


<div class=" g-col-9 mt-12">
<div id="border-style" class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"  >
    <div class="card-body"><div class="d-inline-block "><div class="title-subsection text-light">
<img src="assets/img/smartphone.png" alt="" srcset="" style={{ width: "76%" }}/>
<img  src="assets/img/my-color-1.svg" alt="" srcset="" className='style-appareil-1'/>
</div></div><div class="title-subsection mt-1" style={{ paddingTop: "18%" }}>
<a href='/smartphone'><button class="btn btn--2" type="submit"  >Smartphones</button></a>  
</div></div></div></div>

<div class=" g-col-9 mt-12">
<div id="border-style" class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"  >
    <div class="card-body"><div class="d-inline-block "><div class="title-subsection text-light">
<img src="assets/img/tablette.png" alt="" srcset="" />
<img src="assets/img/my-color.svg" alt="" srcset="" className='style-appareil'/>
</div></div><div class="title-subsection mt-1" style={{ paddingTop: "35%" }}>
<a href='/tablette'> <button class="btn btn--3" type="submit"  >Tablettes</button></a> 
</div></div></div></div>


<div class=" g-col-9 mt-12">
<div id="border-style" class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"  >
    <div class="card-body"><div class="d-inline-block "><div class="title-subsection text-light">
<img src="assets/img/console.png" alt="" srcset="" />
<img src="assets/img/my-color-2.svg" alt="" srcset="" className='style-appareil'/>
</div></div><div class="title-subsection mt-1" style={{ paddingTop: "18%" }}>
<a href='/console'>    <button class="btn btn--4" type="submit"  >Consoles de jeu</button></a>
</div></div></div></div></div>


</section>



        <div class="row mt-5">
        <div class="column9 mt-5">   <span className="get-started-btn-1 ">Création de doubles de clés</span>
   <br/><br/><br/><br/>
   <h1 style={{ fontSize:"60px" }}>Création de doubles <br/>
de clés de voiture</h1>  
   <p >
Nous proposons également une activité secondaire de création de doubles de clés (des services d'usinage et de programmation inclus) . <br/>
Nous sommes en mesure de fournir des devis sur demande pour cette activité, afin de répondre au mieux aux besoins et aux exigences de nos clients.<br/>
 Notre équipe est hautement qualifiée et expérimentée dans ce domaine, ce qui nous permet de garantir des résultats de qualité supérieure à chaque fois.<br/></p>
 <p><i style={{ fontWeight: "500" , }}>
            N'hésitez pas à nous contacter pour plus d'informations sur nos services de création de doubles de clés.</i></p></div>
        <div class="column10"> <img src="assets/img/Group 82.svg" alt="" srcset="" /></div></div>




        <Footer />
    </Fragment>


  );
}

export default Reparation;
