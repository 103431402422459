import React, { Fragment } from 'react';
import Slide from './slider';
import Footer from './footer';
import Header from './header';




function Huawei() {
  return (
    <Fragment>


<Header />



<Slide /> <br/><br/><br/>



<section className="container mt-3">
      <div className="wf-step" aria-label="stepper" role="navigation">
        <a className="wf-step__item wf-step__item--hover " href="/reparations" aria-label="Choix appareil">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
        </a>
        <a className="wf-step__item wf-step__item--ahover" href="/smartphone" aria-label="Marque">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page" style={{ color: "black" }}>Marque</div>
        </a>
        <a className="wf-step__item wf-step__item--active" aria-label="Modèle">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Modèle</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label=">Panne">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Panne</div>
        </a>
        <a className="wf-step__item  wf-step__item--hover" aria-label="BCoordonnées">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
        </a>
      </div>
    </section>

    <div id="md-10" class="wf-grid wf-grid--devices wf-mt--4 wf-mb--3 mt-5">
  <div class="wf-card-container">
    <h5>P40 Pro Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="P40PROPLUS">
        <img src="images/Modeles/P40PROPLUS.png" alt="P40PROPLUS" />
        <sl-visually-hidden class="wf-name">P40PROPLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>P40 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="P40PRO">
        <img src="images/Modeles/P40PRO.png" alt="P40PRO" />
        <sl-visually-hidden class="wf-name">P40PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P40 Lite 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="P40Lite5G">
        <img src="images/Modeles/P40.png" alt="P40Lite5G" />
        <sl-visually-hidden class="wf-name">P40Lite5G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P40 Lite E</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="P40LITEE">
        <img src="images/Modeles/P40LITEE.png" alt="P40LITEE" />
        <sl-visually-hidden class="wf-name">P40LITEE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P40 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="iphone">
        <img src="images/Modeles/P40LITE.png" alt="iP40LITEphone" />
        <sl-visually-hidden class="wf-name">P40LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>


  <div class="wf-card-container">
    <h5>P40</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="P40">
        <img src="images/Modeles/P40.png" alt="P40" />
        <sl-visually-hidden class="wf-name">P40</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>P30 Lite XL</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="P30LiteXL">
        <img src="images/Modeles/P30%20Lite%20XL%20.png" alt="P30LiteXL" />
        <sl-visually-hidden class="wf-name">P30LiteXL</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P30 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIP30PRO">
        <img src="images/Modeles/HUAWEIP30PRO.png" alt="HUAWEIP30PRO" />
        <sl-visually-hidden class="wf-name">HUAWEIP30PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P30 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="iphone">
        <img src="images/Modeles/P30LITE.png" alt="P30LITE" />
        <sl-visually-hidden class="wf-name">P30LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P30</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="P30">
        <img src="images/Modeles/P30.png" alt="P30" />
        <sl-visually-hidden class="wf-name">P30</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P20 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="iphone">
        <img src="images/Modeles/HUAWEIP20PRO.png" alt="HUAWEIP20PRO" />
        <sl-visually-hidden class="wf-name">HUAWEIP20PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P20 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIP20LITE">
        <img src="images/Modeles/HUAWEIP20LITE.png" alt="HUAWEIP20LITE" />
        <sl-visually-hidden class="wf-name">HUAWEIP20LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P20</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="iphone">
        <img src="images/Modeles/HUAWEIP20.png" alt="HUAWEIP20" />
        <sl-visually-hidden class="wf-name">HUAWEIP20</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P10 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="P10PLUS">
        <img src="images/Modeles/P10PLUS.png" alt="P10PLUS" />
        <sl-visually-hidden class="wf-name">P10PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P10 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEI_P10LITE">
        <img src="images/Modeles/HUAWEI_P10LITE.png" alt="HUAWEI_P10LITE" />
        <sl-visually-hidden class="wf-name">HUAWEI_P10LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P10</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIP10">
        <img src="images/Modeles/HUAWEIP10.png" alt="HUAWEIP10" />
        <sl-visually-hidden class="wf-name">HUAWEIP10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P9 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIP9LITE">
        <img src="images/Modeles/HUAWEIP9LITE.png" alt="HUAWEIP9LITE" />
        <sl-visually-hidden class="wf-name">HUAWEIP9LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="iphone">
        <img src="images/Modeles/HUAWEIP9.png" alt="HUAWEIP9" />
        <sl-visually-hidden class="wf-name">HUAWEIP9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P8 Lite 2017</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIP8">
        <img src="images/Modeles/HUAWEIP8.png" alt="HUAWEIP8" />
        <sl-visually-hidden class="wf-name">HUAWEIP8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P8 Lite </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIP8LITE">
        <img src="images/Modeles/HUAWEIP8LITE.png" alt="ipHUAWEIP8LITEhone" />
        <sl-visually-hidden class="wf-name">HUAWEIP8LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIP8">
        <img src="images/Modeles/HUAWEIP8.png" alt="HUAWEIP8" />
        <sl-visually-hidden class="wf-name">HUAWEIP8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P Smart 2021</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="PSMART2021">
        <img src="images/Modeles/P%20SMART%202021.png" alt="PSMART2021" />
        <sl-visually-hidden class="wf-name">PSMART2021</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P Smart 2020</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="PSMART2020">
        <img src="images/Modeles/PSMART2020.png" alt="PSMART2020" />
        <sl-visually-hidden class="wf-name">PSMART2020</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P Smart 2019</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="PSMART2019">
        <img src="images/Modeles/PSMART2019.png" alt="PSMART2019" />
        <sl-visually-hidden class="wf-name">PSMART2019</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P Smart Z</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="PSMARTZ">
        <img src="images/Modeles/PSMARTZ.png" alt="PSMARTZ" />
        <sl-visually-hidden class="wf-name">PSMARTZ</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>P Smart Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIPSMARTPLUS">
        <img src="images/Modeles/HUAWEIPSMARTPLUS.png" alt="HUAWEIPSMARTPLUS" />
        <sl-visually-hidden class="wf-name">HUAWEIPSMARTPLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P Smart</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="PSMART">
        <img src="images/Modeles/PSMART.png" alt="PSMART" />
        <sl-visually-hidden class="wf-name">PSMART</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y9 2019</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y92019">
        <img src="images/Modeles/Y92019.png" alt="Y92019" />
        <sl-visually-hidden class="wf-name">Y92019</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y9">
        <img src="images/Modeles/Y9.png" alt="Y9" />
        <sl-visually-hidden class="wf-name">Y9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y7 2019</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y72019">
        <img src="images/Modeles/Y72019.png" alt="Y72019" />
        <sl-visually-hidden class="wf-name">Y72019</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Y7 Prime 2019</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y7PRIME2019">
        <img src="images/Modeles/Y7PRIME2019.png" alt="Y7PRIME2019" />
        <sl-visually-hidden class="wf-name">Y7PRIME2019</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y7 Prime 2018</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y7PRIME2018">
        <img src="images/Modeles/Y7PRIME2018.png" alt="Y7PRIME2018" />
        <sl-visually-hidden class="wf-name">Y7PRIME2018</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y7 Prime</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y7PRIME">
        <img src="images/Modeles/Y7PRIME.png" alt="Y7PRIME" />
        <sl-visually-hidden class="wf-name">Y7PRIME</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y6P</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y6P">
        <img src="images/Modeles/Y6P.png" alt="Y6P" />
        <sl-visually-hidden class="wf-name">Y6P</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y6 2019 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y62019">
        <img src="images/Modeles/Y62019.png" alt="Y62019" />
        <sl-visually-hidden class="wf-name">Y62019</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Y6 2018</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y62018">
        <img src="images/Modeles/Y62018.png" alt="Y62018" />
        <sl-visually-hidden class="wf-name">Y62018</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y6 Pro 2017 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIY6PRO2017">
        <img src="images/Modeles/HUAWEIY6PRO2017.png" alt="HUAWEIY6PRO2017" />
        <sl-visually-hidden class="wf-name">HUAWEIY6PRO2017</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y6 2017</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y62017">
        <img src="images/Modeles/Y62017.png" alt="Y62017" />
        <sl-visually-hidden class="wf-name">Y62017</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y6 II </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y6II">
        <img src="images/Modeles/Y6II.png" alt="Y6II" />
        <sl-visually-hidden class="wf-name">Y6II</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y5 2019 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y52019">
        <img src="images/Modeles/Y52019.png" alt="Y52019" />
        <sl-visually-hidden class="wf-name">Y52019</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Y5 2018</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIY52018">
        <img src="images/Modeles/HUAWEIY52018.png" alt="HUAWEIY52018" />
        <sl-visually-hidden class="wf-name">HUAWEIY52018</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y5 2</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y5-2">
        <img src="images/Modeles/Y5-2.png" alt="Y5-2" />
        <sl-visually-hidden class="wf-name">Y5-2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Mate 30 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE30PRO">
        <img src="images/Modeles/MATE30PRO.png" alt="MATE30PRO" />
        <sl-visually-hidden class="wf-name">MATE30PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Mate 30 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE20PRO">
        <img src="images/Modeles/HUAWEIMATE20PRO.png" alt="MATE20PRO" />
        <sl-visually-hidden class="wf-name">MATE20PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Mate 20 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIMATE20LITE">
        <img src="images/Modeles/HUAWEIMATE20LITE.png" alt="HUAWEIMATE20LITE" />
        <sl-visually-hidden class="wf-name">HUAWEIMATE20LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Mate 20 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE20">
        <img src="images/Modeles/MATE20.png" alt="MATE20" />
        <sl-visually-hidden class="wf-name">MATE20</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Mate 10 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HUAWEIMATE10PRO">
        <img src="images/Modeles/HUAWEIMATE10PRO.png" alt="HUAWEIMATE10PRO" />
        <sl-visually-hidden class="wf-name">HUAWEIMATE10PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Mate 10 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE10LITE">
        <img src="images/Modeles/MATE10LITE.png" alt="MATE10LITE" />
        <sl-visually-hidden class="wf-name">MATE10LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Mate 10 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE10">
        <img src="images/Modeles/MATE10.png" alt="MATE10" />
        <sl-visually-hidden class="wf-name">MATE10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Mate 9 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE9PRO">
        <img src="images/Modeles/MATE9PRO.png" alt="MATE9PRO" />
        <sl-visually-hidden class="wf-name">MATE9PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Mate 9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE9">
        <img src="images/Modeles/MATE9.png" alt="MATE9" />
        <sl-visually-hidden class="wf-name">MATE9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Mate 8 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE8">
        <img src="images/Modeles/MATE8.png" alt="MATE8" />
        <sl-visually-hidden class="wf-name">MATE8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Mate 7</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE7">
        <img src="images/Modeles/MATE7.png" alt="MATE7" />
        <sl-visually-hidden class="wf-name">MATE7</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Nova 9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="NOVA9">
        <img src="images/Modeles/NOVA%209.png" alt="NOVA9" />
        <sl-visually-hidden class="wf-name">NOVA9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Nova 5T</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="NOVA5T">
        <img src="images/Modeles/NOVA5T.png" alt="NOVA5T" />
        <sl-visually-hidden class="wf-name">NOVA5T</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  

  <div class="wf-card-container">
    <h5>Nova 3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="NOVA3">
        <img src="images/Modeles/NOVA3.png" alt="NOVA3" />
        <sl-visually-hidden class="wf-name">NOVA3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Nova 2 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="NOVA2PLUS">
        <img src="images/Modeles/NOVA2PLUS%20.png" alt="NOVA2PLUS" />
        <sl-visually-hidden class="wf-name">NOVA2PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Nova 2 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="NOVA2">
        <img src="images/Modeles/NOVA2.png" alt="NOVA2" />
        <sl-visually-hidden class="wf-name">NOVA2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Nova</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="NOVA">
        <img src="images/Modeles/NOVA.png" alt="NOVA" />
        <sl-visually-hidden class="wf-name">NOVA</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Nova Smart</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="NOVASMART">
        <img src="images/Modeles/NOVASMART.png" alt="NOVASMART" />
        <sl-visually-hidden class="wf-name">NOVASMART</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>G8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="HONORG8">
        <img src="images/Modeles/HONORG8.png" alt="HONORG8" />
        <sl-visually-hidden class="wf-name">HONORG8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Nexus 6P</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="NEXUS6P">
        <img src="images/Modeles/NEXUS6P.png" alt="NEXUS6P" />
        <sl-visually-hidden class="wf-name">NEXUS6P</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Ascend G6 20S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="ASCENDG620S">
        <img src="images/Modeles/ASCENDG620S.png" alt="ASCENDG620S" />
        <sl-visually-hidden class="wf-name">ASCENDG620S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Y6S </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="Y6S">
        <img src="images/Modeles/Y6II.png" alt="Y6S" />
        <sl-visually-hidden class="wf-name">Y6S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Enjoy 9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="ENJOY9">
        <img src="images/Modeles/ENJOY9.png" alt="ENJOY9" />
        <sl-visually-hidden class="wf-name">ENJOY9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>P20 Lite </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="P20LITE">
        <img src="images/Modeles/P40.png" alt="P20LITE" />
        <sl-visually-hidden class="wf-name">P20LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Mate 40 Pro </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-huawei" aria-label="MATE40PRO">
        <img src="images/Modeles/MATE40PRO.png" alt="MATE40PRO" />
        <sl-visually-hidden class="wf-name">MATE40PRO </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

</div>











      


 <Footer />
    </Fragment>
   

  );
}

export default Huawei;