import React, { Fragment } from 'react';
import Slide from './slider';
import Footer from './footer';
import Header from './header';




function Xiaomi() {
  return (
    <Fragment>


<Header />




<Slide /> <br/><br/><br/>




<section className="container mt-3">
      <div className="wf-step" aria-label="stepper" role="navigation">
        <a className="wf-step__item wf-step__item--hover " href="/reparations" aria-label="Choix appareil">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
        </a>
        <a className="wf-step__item wf-step__item--ahover" href="/smartphone" aria-label="Marque">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page" style={{ color: "black" }}>Marque</div>
        </a>
        <a className="wf-step__item wf-step__item--active" aria-label="Modèle">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Modèle</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label=">Panne">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Panne</div>
        </a>
        <a className="wf-step__item  wf-step__item--hover" aria-label="BCoordonnées">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
        </a>
      </div>
    </section>

    <div id="md-10" class="wf-grid wf-grid--devices wf-mt--4 wf-mb--3 mt-5">
  <div class="wf-card-container">
    <h5>Xiaomi2012</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="Xiaomi2012">
        <img src="images/Modeles/Xiaomi%2012.png" alt="Xiaomi2012" />
        <sl-visually-hidden class="wf-name">Xiaomi2012</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>MI11 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI11PRO">
        <img src="images/Modeles/MI%2011%20PRO.png" alt="MI11PRO" />
        <sl-visually-hidden class="wf-name">MI11PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 11</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI11">
        <img src="images/Modeles/MI%2011.png" alt="MI11" />
        <sl-visually-hidden class="wf-name">MI11</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 11 Lite 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI11">
        <img src="images/Modeles/MI%2011.png" alt="MI11" />
        <sl-visually-hidden class="wf-name">MI11</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 11 Lite 4G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI11LITE4G">
        <img src="images/Modeles/MI11LITE4G.png" alt="MI11LITE4G" />
        <sl-visually-hidden class="wf-name">MI11LITE4G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 11T Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI11TPRO">
        <img src="images/Modeles/MI11TPRO.png" alt="MI11TPRO" />
        <sl-visually-hidden class="wf-name">MI11TPRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 11T </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI11T">
        <img src="images/Modeles/MI%2011.png" alt="MI11T" />
        <sl-visually-hidden class="wf-name">MI11T</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI Note 10</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MINOTE10">
        <img src="images/Modeles/MINOTE10.png" alt="MINOTE10" />
        <sl-visually-hidden class="wf-name">MINOTE10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI Note 10 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MINOTE10LITE">
        <img src="images/Modeles/MINOTE10LITE.png" alt="MINOTE10LITE" />
        <sl-visually-hidden class="wf-name">MINOTE10LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 10T</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI10T">
        <img src="images/Modeles/MI%2010T.png" alt="MI10T" />
        <sl-visually-hidden class="wf-name">MI10T</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 10 Lite 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI10">
        <img src="images/Modeles/MI%2010.png" alt="MI10" />
        <sl-visually-hidden class="wf-name">MI10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 10T Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI10TLITE">
        <img src="images/Modeles/MI%2010.png" alt="MI10TLITE" />
        <sl-visually-hidden class="wf-name">MI10TLITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 10T Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI10TPRO">
        <img src="images/Modeles/MI%2010.png" alt="MI10TPRO" />
        <sl-visually-hidden class="wf-name">MI10TPRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 10 LITE</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI10LITE">
        <img src="images/Modeles/MI%2010%20LITE.png" alt="MI10LITE" />
        <sl-visually-hidden class="wf-name">MI10LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>



  <div class="wf-card-container">
    <h5>MI 10 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI105G">
        <img src="images/Modeles/MINOTE10.png" alt="MI105G" />
        <sl-visually-hidden class="wf-name">MI105G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 10 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="iphone">
        <img src="images/Modeles/MI%2010.png" alt="MINOTE10" />
        <sl-visually-hidden class="wf-name">MINOTE10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>


  <div class="wf-card-container">
    <h5>MI 9T Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI9TPRO">
        <img src="images/Modeles/MI%209T%20PRO.png" alt="MI9TPRO" />
        <sl-visually-hidden class="wf-name">MI9TPRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 9T</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI9T">
        <img src="images/Modeles/MI9T.png" alt="MI9T" />
        <sl-visually-hidden class="wf-name">MI9T</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 9 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI9">
        <img src="images/Modeles/MI9.png" alt="MI9" />
        <sl-visually-hidden class="wf-name">MI9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 9 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI9LITE">
        <img src="images/Modeles/MI9LITE.png" alt="MI9LITE" />
        <sl-visually-hidden class="wf-name">MI9LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 9S E </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI9SE">
        <img src="images/Modeles/MI9SE.png" alt="MI9SE" />
        <sl-visually-hidden class="wf-name">MI9SE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 8 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="XIAMOMIMI8">
        <img src="images/Modeles/XIAMOMIMI8.png" alt="XIAMOMIMI8" />
        <sl-visually-hidden class="wf-name">XIAMOMIMI8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 8 Lite </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI8LITE">
        <img src="images/Modeles/MI8LITE.png" alt="MI8LITE" />
        <sl-visually-hidden class="wf-name">MI8LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 5S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI5S">
        <img src="images/Modeles/MI5S.png" alt="MI5S" />
        <sl-visually-hidden class="wf-name">MI5S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 5</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI5">
        <img src="images/Modeles/MI5.png" alt="MI5" />
        <sl-visually-hidden class="wf-name">MI5</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI A3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MIA3">
        <img src="images/Modeles/IPHONE6PLUS.png" alt="MIA3" />
        <sl-visually-hidden class="wf-name">MIA3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI A2</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MIA2">
        <img src="images/Modeles/MiA2.png" alt="MIA2" />
        <sl-visually-hidden class="wf-name">MIA2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI A1</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MiA1">
        <img src="images/Modeles/MiA1.png" alt="MiA1" />
        <sl-visually-hidden class="wf-name">MiA1</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Poco M3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="POCOM3">
        <img src="images/Modeles/POCOM3.png" alt="POCOM3" />
        <sl-visually-hidden class="wf-name">POCOM3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Poco X3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="POCOX3">
        <img src="images/Modeles/POCO%20X3.png" alt="POCX3" />
        <sl-visually-hidden class="wf-name">POCOX3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Poco F3 5G </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="iphone">
        <img src="images/Modeles/POCOF35G.png" alt="POCOF35G" />
        <sl-visually-hidden class="wf-name">POCOF35G </sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Poco F2 PRO </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="POCOF2PRO">
        <img src="images/Modeles/POCO%20F2%20PRO.png" alt="POCOF2PRO" />
        <sl-visually-hidden class="wf-name">POCOF2PRO </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Poco F1</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="iphone">
        <img src="images/Modeles/PACOPHONEF1.png" alt="PACOPHONEF1" />
        <sl-visually-hidden class="wf-name">PACOPHONEF1 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Redmi Note 11 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE11PRO">
        <img src="images/Modeles/REDMINOTE11PRO.png" alt="REDMINOTE11PRO" />
        <sl-visually-hidden class="wf-name">REDMINOTE11PRO </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Redmi Note 11S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE11S">
        <img src="images/Modeles/REDMI%20NOTE%2011%20S%20.png" alt="REDMINOTE11S" />
        <sl-visually-hidden class="wf-name">REDMINOTE11S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Redmi Note 11</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE11">
        <img src="images/Modeles/REDMINOTE11.png" alt="REDMINOTE11" />
        <sl-visually-hidden class="wf-name">REDMINOTE11 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 10 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="RedmiNote10Pro">
        <img src="images/Modeles/MINOTE10.png" alt="RedmiNote10Pro" />
        <sl-visually-hidden class="wf-name">RedmiNote10Pro</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Redmi Note 10S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE10S">
        <img src="images/Modeles/REDMINOTE10S.png" alt="REDMINOTE10S" />
        <sl-visually-hidden class="wf-name">REDMINOTE10S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 10 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE105G">
        <img src="images/Modeles/MINOTE10.png" alt="REDMINOTE105G" />
        <sl-visually-hidden class="wf-name">REDMINOTE105G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 10</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="RedmiNote10">
        <img src="images/Modeles/REDMI%20NOTE10.png" alt="RedmiNote10" />
        <sl-visually-hidden class="wf-name">RedmiNote10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 9 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE9PRO">
        <img src="images/Modeles/REDMINOTE9PRO.png" alt="REDMINOTE9PRO" />
        <sl-visually-hidden class="wf-name">REDMINOTE9PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 9T</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE9T">
        <img src="images/Modeles/REDMI%20NOTE%209T.png" alt="REDMINOTE9T" />
        <sl-visually-hidden class="wf-name">REDMINOTE9T</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 9S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE9S">
        <img src="images/Modeles/REDMI%20NOTE%209S.png" alt="REDMINOTE9S" />
        <sl-visually-hidden class="wf-name">REDMINOTE9S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE9">
        <img src="images/Modeles/REDMINOTE9.png" alt="REDMINOTE9" />
        <sl-visually-hidden class="wf-name">REDMINOTE9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 8T</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE8T">
        <img src="images/Modeles/REDMINOTE8T.png" alt="REDMINOTE8T" />
        <sl-visually-hidden class="wf-name">REDMINOTE8T</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 8 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE8PRO">
        <img src="images/Modeles/REDMINOTE8PRO.png" alt="REDMINOTE8PRO" />
        <sl-visually-hidden class="wf-name">REDMINOTE8PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE8">
        <img src="images/Modeles/REDMINOTE8.png" alt="REDMINOTE8" />
        <sl-visually-hidden class="wf-name">REDMINOTE8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 7</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE7">
        <img src="images/Modeles/REDMINOTE7.png" alt="REDMINOTE7" />
        <sl-visually-hidden class="wf-name">REDMINOTE7</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 6 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE6PRO">
        <img src="images/Modeles/REDMINOTE6PRO.png" alt="REDMINOTE6PRO" />
        <sl-visually-hidden class="wf-name">REDMINOTE6PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 6</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE6">
        <img src="images/Modeles/REDMINOTE6.png" alt="REDMINOTE6" />
        <sl-visually-hidden class="wf-name">REDMINOTE6</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 5A Prime</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE5APRIME">
        <img src="images/Modeles/REDMINOTE5APRIME.png" alt="REDMINOTE5APRIME" />
        <sl-visually-hidden class="wf-name">REDMINOTE5APRIME</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 5A Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE5PRO">
        <img src="images/Modeles/REDMINOTE5PRO.png" alt="REDMINOTE5PRO" />
        <sl-visually-hidden class="wf-name">REDMINOTE5PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 5 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE5">
        <img src="images/Modeles/REDMINOTE5.png" alt="REDMINOTE5" />
        <sl-visually-hidden class="wf-name">REDMINOTE5</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 4X</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE4X">
        <img src="images/Modeles/REDMINOTE4X.png" alt="REDMINOTE4X" />
        <sl-visually-hidden class="wf-name">REDMINOTE4X</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 4 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE4">
        <img src="images/Modeles/REDMINOTE4.png" alt="REDMINOTE4" />
        <sl-visually-hidden class="wf-name">REDMINOTE4</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi Note 3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMINOTE3">
        <img src="images/Modeles/REDMINOTE3.png" alt="REDMINOTE3" />
        <sl-visually-hidden class="wf-name">REDMINOTE3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 10 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="Redmi10">
        <img src="images/Modeles/REDMI%2010T%20LITE.png" alt="Redmi10" />
        <sl-visually-hidden class="wf-name">Redmi10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 10 A</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI10A">
        <img src="images/Modeles/REDMI10A.png" alt="REDMI10A" />
        <sl-visually-hidden class="wf-name">REDMI10A</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 10 C</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI10C">
        <img src="images/Modeles/REDMI10C.png" alt="REDMI10C" />
        <sl-visually-hidden class="wf-name">REDMI10C</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 9T</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI9T">
        <img src="images/Modeles/REDMI%209T.png" alt="REDMI9T" />
        <sl-visually-hidden class="wf-name">REDMI9T</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 9A</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI9A">
        <img src="images/Modeles/REDMI%209A.png" alt="REDMI9A" />
        <sl-visually-hidden class="wf-name">REDMI9A</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI9">
        <img src="images/Modeles/REDMI%209.png" alt="REDMI9" />
        <sl-visually-hidden class="wf-name">REDMI9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 9C</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="redmi9c">
        <img src="images/Modeles/redmi9c.png" alt="redmi9c" />
        <sl-visually-hidden class="wf-name">redmi9c </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI8">
        <img src="images/Modeles/REDMI8.png" alt="REDMI8" />
        <sl-visually-hidden class="wf-name">REDMI8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 7A</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI7A">
        <img src="images/Modeles/REDMI7A.png" alt="REDMI7A" />
        <sl-visually-hidden class="wf-name">REDMI7A</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 7</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI7">
        <img src="images/Modeles/REDMI%207.png" alt="REDMI7" />
        <sl-visually-hidden class="wf-name">REDMI7</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 6A</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI6A">
        <img src="images/Modeles/REDMI6A.png" alt="REDMI6A" />
        <sl-visually-hidden class="wf-name">REDMI6A</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

 
  
  <div class="wf-card-container">
    <h5>Redmi 6 Dual Sim</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI6DUALSIM">
        <img src="images/Modeles/REDMI6DUALSIM.png" alt="REDMI6DUALSIM" />
        <sl-visually-hidden class="wf-name">REDMI6DUALSIM </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Redmi 6</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI6">
        <img src="images/Modeles/REDMI6.png" alt="REDMI6" />
        <sl-visually-hidden class="wf-name">REDMI6 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Redmi 5A </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI5A">
        <img src="images/Modeles/REDMI5A.png" alt="REDMI5A" />
        <sl-visually-hidden class="wf-name">REDMI5A </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Redmi 5 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI5PLUS">
        <img src="images/Modeles/REDMI5PLUS.png" alt="REDMI5PLUS" />
        <sl-visually-hidden class="wf-name">REDMI5PLUS </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Redmi 5</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI5">
        <img src="images/Modeles/REDMI5.png" alt="REDMI5" />
        <sl-visually-hidden class="wf-name">REDMI5 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Redmi 4X</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI4X">
        <img src="images/Modeles/REDMI4X.png" alt="REDMI4X" />
        <sl-visually-hidden class="wf-name">REDMI4X </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Redmi 4A</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI4A">
        <img src="images/Modeles/REDMI4A.png" alt="REDMI4A" />
        <sl-visually-hidden class="wf-name">REDMI4A </sl-visually-hidden>
      </a>
    </sl-card>
  </div>


  <div class="wf-card-container">
    <h5>Redmi S2</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMIS2">
        <img src="images/Modeles/REDMIS2.png" alt="REDMIS2" />
        <sl-visually-hidden class="wf-name">REDMIS2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>MI Mix3 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MIMIX3">
        <img src="images/Modeles/MIMIX3.png" alt="MIMIX3" />
        <sl-visually-hidden class="wf-name">MIMIX3 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI Mix 2S </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MIMIX2S">
        <img src="images/Modeles/MIMIX2S.png" alt="MIMIX2S" />
        <sl-visually-hidden class="wf-name">MIMIX2S </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>MI Mix 2 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="iphone">
        <img src="images/Modeles/MIMIX2.png" alt="MIMIX2" />
        <sl-visually-hidden class="wf-name">MIMIX2 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

   
  <div class="wf-card-container">
    <h5>MI Max 3 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MIMAX3">
        <img src="images/Modeles/MIMAX3.png" alt="MIMAX3" />
        <sl-visually-hidden class="wf-name">MIMAX3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Mi Max 2</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MIMAX2">
        <img src="images/Modeles/MIMAX2.png" alt="MIMAX2" />
        <sl-visually-hidden class="wf-name">MIMAX2 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Mi Max</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MIMAX">
        <img src="images/Modeles/MIMAX.png" alt="MIMAX" />
        <sl-visually-hidden class="wf-name">MIMAX </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

   
  <div class="wf-card-container">
    <h5>Redmi 10 2022</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="REDMI102022">
        <img src="images/Modeles/REDMI%2010%202022.png" alt="REDMI102022" />
        <sl-visually-hidden class="wf-name">REDMI102022</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>MI 11i 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI 11i 5G">
        <img src="images/Modeles/MI%2011i%205G.png" alt="MI 11i 5G" />
        <sl-visually-hidden class="wf-name">MI 11i 5G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>MI 10 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-xiaomi" aria-label="MI10PRO">
        <img src="images/Modeles/Mi%2010%20PRO%20.png" alt="MI10PRO" />
        <sl-visually-hidden class="wf-name">MI10PRO </sl-visually-hidden>
      </a>
    </sl-card>
  </div>
   




</div>











      


 <Footer />
    </Fragment>
   

  );
}

export default Xiaomi;