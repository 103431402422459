import React, { Fragment } from 'react';
import Slide from './slider';
import Header from './header';
import Footer from './footer';




function Panne1() {
  return (
    <Fragment>

<Header />



<Slide /> <br/><br/><br/>




<section className="container mt-3">
      <div className="wf-step" aria-label="stepper" role="navigation">
        <a className="wf-step__item wf-step__item--hover " href="/reparations" aria-label="Choix appareil">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
        </a>
        <a className="wf-step__item wf-step__item--ahover" href="/smartphone" aria-label="Marque">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page" style={{ color: "black" }}>Marque</div>
        </a>
        <a className="wf-step__item wf-step__item--active" aria-label="Modèle">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Modèle</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label=">Panne">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Panne</div>
        </a>
        <a className="wf-step__item  wf-step__item--hover" aria-label="BCoordonnées">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
        </a>
      </div>
    </section>

    <div id="md-10" class="wf-grid wf-grid--devices wf-mt--4 wf-mb--3 mt-5">
  <div class="wf-card-container">
    <h5>Galaxy S23 ultra</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="galaxy-s23-ultra">
        <img src="images/Modeles/GALAXYS23ULTRA.png" alt="galaxy-s23-ultra" />
        <sl-visually-hidden class="wf-name">GALAXY S23 ULTRA</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
 

  <div class="wf-card-container">
    <h5>Galaxy S23</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GalaxyS23">
        <img src="images/Modeles/GALAXYS23PLUS.png" alt="Galaxy S23" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Galaxy S23</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="" aria-label="GalaxyS23">
        <img src="images/Modeles/GALAXYS23.png" alt="galaxy-s23" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S22 Ultra</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS22ULTRA">
        <img src="images/Modeles/GALAXYS22ULTRA.png" alt="GALAXYS22ULTRA" />
        <sl-visually-hidden class="wf-name">GALAXYS22ULTRA</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S22 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS22PLUS">
        <img src="images/Modeles/GALAXYS22PLUS.png" alt="GALAXYS22PLUS" />
        <sl-visually-hidden class="wf-name">GALAXYS22PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S22</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS22">
        <img src="images/Modeles/GALAXYS22.png" alt="GALAXYS22" />
        <sl-visually-hidden class="wf-name">GALAXYS22</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S21 Ultra </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS1ULTRA">
        <img src="images/Modeles/GALAXYS1ULTRA.png" alt="GALAXYS1ULTRA" />
        <sl-visually-hidden class="wf-name">GALAXYS1ULTRA</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S21 FE5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS21FE5G">
        <img src="images/Modeles/GALAXYS21FE5G.png" alt="GALAXYS21FE5G" />
        <sl-visually-hidden class="wf-name">GALAXYS21FE5G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S21 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXYS1PLUS.png" alt="GALAXYS1PLUS" />
        <sl-visually-hidden class="wf-name">GALAXYS1PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S21 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GalaxyS215G">
        <img src="images/Modeles/GALAXY%20S21%205G.png" alt="GalaxyS215G" />
        <sl-visually-hidden class="wf-name">Galaxy S21 5G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy FE20 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXY%20S20%20FE%205G.png" alt="GalaxyFE205G" />
        <sl-visually-hidden class="wf-name">GalaxyFE205G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S20 FE</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS20FE">
        <img src="images/Modeles/GALAXYS20FE.png" alt="GALAXYS20FE" />
        <sl-visually-hidden class="wf-name">GALAXYS20FE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy 20 Ultra</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS20ULTRA">
        <img src="images/Modeles/GALAXYS20ULTRA.png" alt="GALAXYS20ULTRA" />
        <sl-visually-hidden class="wf-name">GALAXYS20ULTRA</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S20 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS20PLUS">
        <img src="images/Modeles/GALAXYS20PLUS.png" alt="GALAXYS20PLUS" />
        <sl-visually-hidden class="wf-name">GALAXYS20PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy 20 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS205G">
        <img src="images/Modeles/GALAXYS205G.png" alt="GALAXYS205G" />
        <sl-visually-hidden class="wf-name">GALAXYS205G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy 20</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS20">
        <img src="images/Modeles/GALAXYS20.png" alt="GALAXYS20" />
        <sl-visually-hidden class="wf-name">GALAXYS20</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S10E</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS10E">
        <img src="images/Modeles/GALAXYS10E.png" alt="GALAXYS10E" />
        <sl-visually-hidden class="wf-name">GALAXYS10E</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S10 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS1LITE">
        <img src="images/Modeles/GALAXY%20S10%20LITE.png" alt="GALAXYS1LITE" />
        <sl-visually-hidden class="wf-name">GALAXYS1LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S10 Plus </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS10PLUS">
        <img src="images/Modeles/GALAXYS10PLUS.png" alt="GALAXYS10PLUS" />
        <sl-visually-hidden class="wf-name">GALAXYS10PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S10 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS105G">
        <img src="images/Modeles/GALAXYS105G.png" alt="GALAXYS105G" />
        <sl-visually-hidden class="wf-name">GALAXYS105G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S10</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS10">
        <img src="images/Modeles/GALAXYS10.png" alt="GALAXYS10" />
        <sl-visually-hidden class="wf-name">GALAXYS10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S9 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS9P">
        <img src="images/Modeles/GALAXYS9P.png" alt="GALAXYS9P" />
        <sl-visually-hidden class="wf-name">GALAXYS9P</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS9">
        <img src="images/Modeles/GALAXYS9.png" alt="GALAXYS9" />
        <sl-visually-hidden class="wf-name">GALAXYS9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S8 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS8">
        <img src="images/Modeles/GALAXYS8.png" alt="GALAXYS8" />
        <sl-visually-hidden class="wf-name">GALAXYS8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS8">
        <img src="images/Modeles/GALAXYS8.png" alt="GALAXYS8" />
        <sl-visually-hidden class="wf-name">GALAXYS8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy 7 Edge</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS7EDGE">
        <img src="images/Modeles/GALAXYS7EDGE.png" alt="GALAXYS7EDGE" />
        <sl-visually-hidden class="wf-name">GALAXYS7EDGE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S7</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS7">
        <img src="images/Modeles/GALAXYS7.png" alt="GALAXYS7" />
        <sl-visually-hidden class="wf-name">GALAXYS7</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S6 Edge Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GS6EdgePlus">
        <img src="images/Modeles/GS6EdgePlus.png" alt="GS6EdgePlus" />
        <sl-visually-hidden class="wf-name">GS6EdgePlus</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S6 Edge</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS6EDGE">
        <img src="images/Modeles/GALAXYS6EDGE.png" alt="GALAXYS6EDGE" />
        <sl-visually-hidden class="wf-name">GALAXYS6EDGE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S6</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS6">
        <img src="images/Modeles/GALAXYS6.png" alt="GALAXYS6" />
        <sl-visually-hidden class="wf-name">GALAXYS6</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  


  <div class="wf-card-container">
    <h5>Galaxy S5 Neo </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung/panne-samsung" aria-label="GALAXYS5NEO">
        <img src="images/Modeles/GALAXYS5NEO.png" alt="GALAXYS5NEO" />
        <sl-visually-hidden class="wf-name">GALAXYS5NEO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S5 Mini</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GS5mini">
        <img src="images/Modeles/GS5mini.png" alt="GS5mini" />
        <sl-visually-hidden class="wf-name">GS5mini</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S5</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS5">
        <img src="images/Modeles/GALAXYS5.png" alt="GALAXYS5" />
        <sl-visually-hidden class="wf-name">GALAXYS5</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S4 Active</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS4ACTIVE">
        <img src="images/Modeles/GALAXYS4ACTIVE.png" alt="GALAXYS4ACTIVE" />
        <sl-visually-hidden class="wf-name">GALAXYS4ACTIVE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S4 Mini</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS4MINI">
        <img src="images/Modeles/GALAXYS4MINI.png" alt="GALAXYS4MINI" />
        <sl-visually-hidden class="wf-name">GALAXYS4MINI</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S4</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS4">
        <img src="images/Modeles/GALAXYS4.png" alt="GALAXYS4" />
        <sl-visually-hidden class="wf-name">GALAXYS4</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S3 Mini</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS3MINI">
        <img src="images/Modeles/GALAXYS3MINI.png" alt="GALAXYS3MINI" />
        <sl-visually-hidden class="wf-name">GALAXYS3MINI</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS3">
        <img src="images/Modeles/GALAXYS3.png" alt="GALAXYS3" />
        <sl-visually-hidden class="wf-name">GALAXYS3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S2</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYS2">
        <img src="images/Modeles/GALAXYS2.png" alt="GALAXYS2" />
        <sl-visually-hidden class="wf-name">GALAXYS2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy S Duos</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYSDUOS">
        <img src="images/Modeles/GALAXYSDUOS.png" alt="GALAXYSDUOS" />
        <sl-visually-hidden class="wf-name">GALAXYSDUOS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Z Fold 4</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYZFOLD4">
        <img src="images/Modeles/GALAXY%20Zfold%204.png" alt="GALAXYZFOLD4" />
        <sl-visually-hidden class="wf-name">GALAXYZFOLD4</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Z Fold 3 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYZFOLD35G">
        <img src="images/Modeles/GALAXYFOLDZFLIP4.png" alt="GALAXYZFOLD35G" />
        <sl-visually-hidden class="wf-name">GALAXYZFOLD35G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Z Fold 2 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYZFOLD25G">
        <img src="images/Modeles/GZFOLD%202%205G.png" alt="GALAXYZFOLD25G" />
        <sl-visually-hidden class="wf-name">GALAXYZFOLD25G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Fold</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYFOLD">
        <img src="images/Modeles/GALAXYFOLD.png" alt="GALAXYFOLD" />
        <sl-visually-hidden class="wf-name">GALAXYFOLD</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Flip 4</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYFLIP4">
        <img src="images/Modeles/GALAXYFLIP4.png" alt="GALAXYFLIP4" />
        <sl-visually-hidden class="wf-name">GALAXYFLIP4</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Flip 3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYFLIP3">
        <img src="images/Modeles/GALAXYFLIP3.png" alt="GALAXYFLIP3" />
        <sl-visually-hidden class="wf-name">GALAXYFLIP3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Z Flip</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXZFLIP2.png" alt="GALAXZFLIP2" />
        <sl-visually-hidden class="wf-name">GALAXZFLIP2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 20 Ultra 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXYNote20ULTRA.png" alt="GALAXYNote20ULTRA" />
        <sl-visually-hidden class="wf-name">GALAXYNote20ULTRA</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 20 Ultra</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNote20ULTRA">
        <img src="images/Modeles/GALAXY%20NOTE%2020%20ULTRA.png" alt="GALAXYNote20ULTRA" />
        <sl-visually-hidden class="wf-name">GALAXYNote20ULTRA</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Galaxy Note 20 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE20">
        <img src="images/Modeles/GALAXY%20NOTE%2020.png" alt="GALAXY0NOTE20" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE20</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 20</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE20">
        <img src="images/Modeles/GALAXY%20NOTE%2020.png" alt="GALAXYNOTE20" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE20</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 10 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE10LITE">
        <img src="images/Modeles/GALAXYNOTE10LITE.png" alt="GALAXYNOTE10LITE" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE10LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 10 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE10PLUS">
        <img src="images/Modeles/GALAXYNOTE10PLUS.png" alt="GALAXYNOTE10PLUS" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE10PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 10</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE10">
        <img src="images/Modeles/GALAXYNOTE10.png" alt="GALAXYNOTE10" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE9">
        <img src="images/Modeles/GALAXYNOTE9.png" alt="GALAXYNOTE9" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE8">
        <img src="images/Modeles/GALAXYNOTE8.png" alt="GALAXYNOTE8" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Galaxy Note 5</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE5">
        <img src="images/Modeles/GALAXYNOTE5.png" alt="GALAXYNOTE5" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE5</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 4</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXYNOTE4.png" alt="GALAXYNOTE4" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE4</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 3 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE3LITE">
        <img src="images/Modeles/GALAXYNOTE3LITE.png" alt="GALAXYNOTE3LITE" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE3LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE3">
        <img src="images/Modeles/GALAXYNOTE3.png" alt="GALAXYNOTE3" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 2</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXYNOTE2.png" alt="GALAXYNOTE2" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Note 1</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYNOTE1">
        <img src="images/Modeles/GALAXYNOTE1.png" alt="GALAXYNOTE1" />
        <sl-visually-hidden class="wf-name">GALAXYNOTE1</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A90</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA90">
        <img src="images/Modeles/GALAXYA90.png" alt="GALAXYA90" />
        <sl-visually-hidden class="wf-name">GALAXYA90</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A80</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXYA80.png" alt="GALAXYA80" />
        <sl-visually-hidden class="wf-name">GALAXYA80</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A72</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXY0A72">
        <img src="images/Modeles/GALAXY%20A72.png" alt="GALAXY0A72" />
        <sl-visually-hidden class="wf-name">GALAXY0A72</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A71</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA71">
        <img src="images/Modeles/GALAXYA71.png" alt="GALAXYA71" />
        <sl-visually-hidden class="wf-name">GALAXYA71</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A70</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA70">
        <img src="images/Modeles/GALAXYA70.png" alt="GALAXYA70" />
        <sl-visually-hidden class="wf-name">GALAXYA70</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A60</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA60">
        <img src="images/Modeles/GALAXYA60.png" alt="GALAXYA60" />
        <sl-visually-hidden class="wf-name">GALAXYA60</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A52 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA52S5G">
        <img src="images/Modeles/GALAXY%2052S.png" alt="GALAXYA52S5G" />
        <sl-visually-hidden class="wf-name">GALAXYA52S5G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A52 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GalaxyA52 ">
        <img src="images/Modeles/GALAXY%20A52.png" alt="GalaxA52 " />
        <sl-visually-hidden class="wf-name">GalaxyA52 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A53 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA535G">
        <img src="images/Modeles/GALAXY%2053.png" alt="GALAXYA535G" />
        <sl-visually-hidden class="wf-name">GALAXYA535G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A51 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA515G">
        <img src="images/Modeles/GALAXYA515G.png" alt="GALAXYA515G" />
        <sl-visually-hidden class="wf-name">GALAXYA515G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A51</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA51">
        <img src="images/Modeles/GALAXYA51.png" alt="GALAXYA51" />
        <sl-visually-hidden class="wf-name">GALAXYA51</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A50 S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA50S">
        <img src="images/Modeles/GALAXY%20A50S.png" alt="GALAXYA50S" />
        <sl-visually-hidden class="wf-name">GALAXYA50S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A50</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA50">
        <img src="images/Modeles/GALAXYA50.png" alt="GALAXYA50" />
        <sl-visually-hidden class="wf-name">GALAXYA50</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A42 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA425G">
        <img src="images/Modeles/GALAXYA425G.png" alt="GALAXYA425G" />
        <sl-visually-hidden class="wf-name">GALAXYA425G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A41</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA41">
        <img src="images/Modeles/GALAXYA41.png" alt="GALAXYA41" />
        <sl-visually-hidden class="wf-name">GALAXYA41</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A40</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA40">
        <img src="images/Modeles/GALAXYA40.png" alt="GALAXYA40" />
        <sl-visually-hidden class="wf-name">GALAXYA40</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A33 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA335G">
        <img src="images/Modeles/GA335G.png" alt="GALAXYA335G" />
        <sl-visually-hidden class="wf-name">GALAXYA335G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A32 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA335G">
        <img src="images/Modeles/GALAXY%20A32%205G.png" alt="GALAXYA325G" />
        <sl-visually-hidden class="wf-name">GALAXYA325G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A32</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA32">
        <img src="images/Modeles/GALAXY%20A32.png" alt="GALAXYA32" />
        <sl-visually-hidden class="wf-name">GALAXYA32</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A31</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA31">
        <img src="images/Modeles/GALAXYA31.png" alt="GALAXYA31" />
        <sl-visually-hidden class="wf-name">GALAXYA1</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A30 S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA30S">
        <img src="images/Modeles/GALAXYA30S.png" alt="GALAXYA30S" />
        <sl-visually-hidden class="wf-name">GALAXYA30S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A30</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA30">
        <img src="images/Modeles/GALAXYA30.png" alt="GALAXYA30" />
        <sl-visually-hidden class="wf-name">GALAXYA30</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A23 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA235G">
        <img src="images/Modeles/GALAXY%20A23%205G.png" alt="GALAXYA235G" />
        <sl-visually-hidden class="wf-name">GALAXYA235G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A22 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA225G">
        <img src="images/Modeles/GALAXY%2022.png" alt="GALAXYA225G" />
        <sl-visually-hidden class="wf-name">GALAXYA225G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A22</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA22">
        <img src="images/Modeles/GALAXY%2022.png" alt="GALAXYA22" />
        <sl-visually-hidden class="wf-name">GALAXYA22</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A21 S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA21S">
        <img src="images/Modeles/GALAXYA21S.png" alt="GALAXYA21S" />
        <sl-visually-hidden class="wf-name">GALAXYA21S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A20 E</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA20E">
        <img src="images/Modeles/GALAXYA20E.png" alt="GALAXYA20E" />
        <sl-visually-hidden class="wf-name">GALAXYA20E</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A20 S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung/panne-samsung" aria-label="GALAXYA20S">
        <img src="images/Modeles/GALAXY%20A20S.png" alt="GALAXYA20S" />
        <sl-visually-hidden class="wf-name">GALAXYA20S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A20</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXYA20.png" alt="GALAXYA20" />
        <sl-visually-hidden class="wf-name">GALAXYA20</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A13 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA135G">
        <img src="images/Modeles/GALAXY%20A13%205G.png" alt="GALAXYA135G" />
        <sl-visually-hidden class="wf-name">GALAXYA135G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A13 S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA13S">
        <img src="images/Modeles/GALAXYA13S.png" alt="GALAXYA13S" />
        <sl-visually-hidden class="wf-name">GALAXYA13S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A13</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA13">
        <img src="images/Modeles/GALAXY%2013.png" alt="GALAXYA13" />
        <sl-visually-hidden class="wf-name">GALAXYA13</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A12 S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA12S">
        <img src="images/Modeles/galaxy-a12s.png" alt="GALAXYA12S" />
        <sl-visually-hidden class="wf-name">GALAXYA12S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A12</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA12">
        <img src="images/Modeles/GA12.png" alt="GALAXYA12" />
        <sl-visually-hidden class="wf-name">GALAXYA12</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A11</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA11">
        <img src="images/Modeles/GA11.png" alt="GALAXYA11" />
        <sl-visually-hidden class="wf-name">GALAXYA11</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A10 S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA10S">
        <img src="images/Modeles/GALAXYA10S.png" alt="GALAXYA10S" />
        <sl-visually-hidden class="wf-name">GALAXYA10S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A10</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXYA10.png" alt="GALAXYA10" />
        <sl-visually-hidden class="wf-name">GALAXYA10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA9">
        <img src="images/Modeles/GALAXYA9.png" alt="GALAXYA9" />
        <sl-visually-hidden class="wf-name">GALAXYA9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA8">
        <img src="images/Modeles/GALAXYA8.png" alt="GALAXYA8" />
        <sl-visually-hidden class="wf-name">GALAXYA8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Galaxy A7 2018</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA72018">
        <img src="images/Modeles/GALAXYA72018.png" alt="GALAXYA72018" />
        <sl-visually-hidden class="wf-name">GALAXYA72018</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A7</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA7">
        <img src="images/Modeles/GALAXYA7.png" alt="GALAXYA7" />
        <sl-visually-hidden class="wf-name">GALAXYA7</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A7 2015</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA72015">
        <img src="images/Modeles/GALAXYA72015.png" alt="GALAXYA72015" />
        <sl-visually-hidden class="wf-name">GALAXYA72015</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A6 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA6PLUS">
        <img src="images/Modeles/GALAXYA6PLUS.png" alt="GALAXYA6PLUS" />
        <sl-visually-hidden class="wf-name">GALAXYA6PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A6</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA6">
        <img src="images/Modeles/GALAXYA6.png" alt="GALAXYA6" />
        <sl-visually-hidden class="wf-name">GALAXYA6</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A5 2017</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA52017">
        <img src="images/Modeles/GALAXYA52017.png" alt="GALAXYA52017" />
        <sl-visually-hidden class="wf-name">GALAXYA52017</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A5 2016</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA52016">
        <img src="images/Modeles/GALAXYA52016.png" alt="GALAXYA52016" />
        <sl-visually-hidden class="wf-name">GALAXYA52016</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A5</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA5">
        <img src="images/Modeles/GALAXYA5.png" alt="GALAXYA5" />
        <sl-visually-hidden class="wf-name">GALAXYA5</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A04 S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA04S">
        <img src="images/Modeles/GALAXYA04S.png" alt="GALAXYA04S" />
        <sl-visually-hidden class="wf-name">GALAXYA04S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A3 2017</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA32017">
        <img src="images/Modeles/GALAXYA32017.png" alt="GALAXYA32017" />
        <sl-visually-hidden class="wf-name">GALAXYA32017</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A3 2016</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA32016">
        <img src="images/Modeles/GALAXYA32016.png" alt="GALAXYA32016" />
        <sl-visually-hidden class="wf-name">GALAXYA32016</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA3">
        <img src="images/Modeles/GALAXYA3.png" alt="GALAXYA3" />
        <sl-visually-hidden class="wf-name">GALAXYA3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A03</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA03">
        <img src="images/Modeles/GALAXY%2003.png" alt="GALAXYA03" />
        <sl-visually-hidden class="wf-name">GALAXYA03</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A03 S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA03S">
        <img src="images/Modeles/GALAXY%20A03S.png" alt="GALAXYA03S" />
        <sl-visually-hidden class="wf-name">GALAXYA03S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A02S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA02S">
        <img src="images/Modeles/GALAXY%20A02S.png" alt="GALAXYA02S" />
        <sl-visually-hidden class="wf-name">GALAXYA02S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy A01</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYA02">
        <img src="images/Modeles/GALAXY%20A01.png" alt="GALAXYA01" />
        <sl-visually-hidden class="wf-name">GALAXYA02</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ8">
        <img src="images/Modeles/GALAXYJ8.png" alt="GALAXYJ8" />
        <sl-visually-hidden class="wf-name">GALAXYJ8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy M51</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXY M51">
        <img src="images/Modeles/GALAXY%20M51.png" alt="GALAXY M51" />
        <sl-visually-hidden class="wf-name">GALAXY M51</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy M32 5G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYM325G">
        <img src="images/Modeles/GALAXYM325G.png" alt="GALAXYM325G" />
        <sl-visually-hidden class="wf-name">GALAXYM325G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy M32</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYM32">
        <img src="images/Modeles/GALAXYM32.png" alt="GALAXYM32" />
        <sl-visually-hidden class="wf-name">GALAXYM32</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy M31</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYM31">
        <img src="images/Modeles/GALAXY%20M31.png" alt="GALAXYM31" />
        <sl-visually-hidden class="wf-name">GALAXYM31</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy M23</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYM23">
        <img src="images/Modeles/GALAXYM23.png" alt="GALAXYM23" />
        <sl-visually-hidden class="wf-name">GALAXYM23</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy M20</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYM20">
        <img src="images/Modeles/GALAXYM20.png" alt="GALAXYM20" />
        <sl-visually-hidden class="wf-name">GALAXYM20</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy M12</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYM12">
        <img src="images/Modeles/GALAXY%20M12.png" alt="GALAXYM12" />
        <sl-visually-hidden class="wf-name">GALAXYM12</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy M11</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYM11">
        <img src="images/Modeles/GALAXYM11.png" alt="GALAXYM11" />
        <sl-visually-hidden class="wf-name">GALAXYM11</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J7 2017</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ72017">
        <img src="images/Modeles/GALAXYJ72017.png" alt="GALAXYJ72017" />
        <sl-visually-hidden class="wf-name">GALAXYJ72017</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J7 2016</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ72016">
        <img src="images/Modeles/GALAXYJ72016.png" alt="GALAXYJ72016" />
        <sl-visually-hidden class="wf-name">GALAXYJ72016</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J6 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ6PLUS">
        <img src="images/Modeles/GALAXYJ6PLUS.png" alt="GALAXYJ6PLUS" />
        <sl-visually-hidden class="wf-name">GALAXYJ6PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J6</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ6">
        <img src="images/Modeles/GALAXYJ6.png" alt="GALAXYJ6" />
        <sl-visually-hidden class="wf-name">GALAXYJ6</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J5 2017</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ52017">
        <img src="images/Modeles/GALAXYJ52017.png" alt="GALAXYJ52017" />
        <sl-visually-hidden class="wf-name">GALAXYJ52017</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J5 2016</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ52016">
        <img src="images/Modeles/GALAXYJ52016.png" alt="GALAXYJ52016" />
        <sl-visually-hidden class="wf-name">GALAXYJ52016</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J5 2015</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ52015">
        <img src="images/Modeles/GALAXYJ52015.png" alt="GALAXYJ52015" />
        <sl-visually-hidden class="wf-name">GALAXYJ52015</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J5</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ5">
        <img src="images/Modeles/GALAXYJ5.png" alt="GALAXYJ5" />
        <sl-visually-hidden class="wf-name">GALAXYJ5</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J4 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ4PLUS">
        <img src="images/Modeles/GALAXYJ4PLUS.png" alt="GALAXYJ4PLUS" />
        <sl-visually-hidden class="wf-name">GALAXYJ4PLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J4</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ4">
        <img src="images/Modeles/GALAXYJ4.png" alt="GALAXYJ4" />
        <sl-visually-hidden class="wf-name">GALAXYJ4</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J3 2017</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ32017">
        <img src="images/Modeles/GALAXYJ32017.png" alt="GALAXYJ32017" />
        <sl-visually-hidden class="wf-name">GALAXYJ32017</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J3 2016</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ32016">
        <img src="images/Modeles/GALAXYJ32016.png" alt="GALAXYJ32016" />
        <sl-visually-hidden class="wf-name">GALAXYJ32016</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J2 Pro 2018</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ2PRO2018">
        <img src="images/Modeles/GALAXYJ2PRO2018(GJ250F).png" alt="GALAXYJ2PRO2018" />
        <sl-visually-hidden class="wf-name">GALAXYJ2PRO2018</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J1 2016</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ12016">
        <img src="images/Modeles/GALAXYJ12016.png" alt="GALAXYJ12016" />
        <sl-visually-hidden class="wf-name">GALAXYJ12016</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J1 2015</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ12015">
        <img src="images/Modeles/J12015.png" alt="GALAXYJ12015" />
        <sl-visually-hidden class="wf-name">GALAXYJ12015 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy J1 Mini</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYJ1MINI">
        <img src="images/Modeles/GALAXYJ1MINI.png" alt="GALAXYJ1MINI" />
        <sl-visually-hidden class="wf-name">GALAXYJ1MINI</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Alpha</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYALPHA">
        <img src="images/Modeles/GALAXYALPHA.png" alt="GALAXYALPHA" />
        <sl-visually-hidden class="wf-name">GALAXYALPHA</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Grand 2</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYGRAND2">
        <img src="images/Modeles/GALAXYGRAND2.png" alt="GALAXYGRAND2" />
        <sl-visually-hidden class="wf-name">GALAXYGRAND2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Grand Neo </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYGRANDNEO">
        <img src="images/Modeles/GRANDNEO.png" alt="GALAXYGRANDNEO" />
        <sl-visually-hidden class="wf-name">GALAXYGRANDNEO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Grand Prime VE</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYGRANDPRIME">
        <img src="images/Modeles/GALAXYGRANDPRIMEVE.png" alt="GALAXYGRANDPRIME" />
        <sl-visually-hidden class="wf-name">GALAXYGRANDPRIME </sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Galaxy Grand Prime</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYGRANDPRIME">
        <img src="images/Modeles/GALAXYGRANDPRIME.png" alt="GALAXYGRANDPRIME" />
        <sl-visually-hidden class="wf-name">GALAXYGRANDPRIME</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Grand Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="iphone">
        <img src="images/Modeles/GALAXYGRANDPLUS.png" alt="GALAXYGRANDPLUS" />
        <sl-visually-hidden class="wf-name">GALAXYGRANDPLUS</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Grand</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYGRAND">
        <img src="images/Modeles/GALAXYGRAND.png" alt="GALAXYGRAND" />
        <sl-visually-hidden class="wf-name">GALAXYGRAND</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Mega</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYMEGA">
        <img src="images/Modeles/GALAXYMEGA.png" alt="GALAXYMEGA" />
        <sl-visually-hidden class="wf-name">GALAXYMEGA</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Core Prime 4G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYCOREPRIME4G">
        <img src="images/Modeles/GALAXYCOREPRIME4G.png" alt="GALAXYCOREPRIME4G" />
        <sl-visually-hidden class="wf-name">GALAXYCOREPRIME4G </sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Galaxy Core Prime</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYCOREPRIME">
        <img src="images/Modeles/GALAXYCOREPRIME.png" alt="GALAXYCOREPRIME" />
        <sl-visually-hidden class="wf-name">GALAXYCOREPRIME</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Core 4G</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYCORE4G">
        <img src="images/Modeles/GALAXYCORE4G.png" alt="GALAXYCORE4G" />
        <sl-visually-hidden class="wf-name">GALAXYCORE4G</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Experss 2 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYEXPRESS2">
        <img src="images/Modeles/GALAXYEXPRESS2.png" alt="GALAXYEXPRESS2" />
        <sl-visually-hidden class="wf-name">GALAXYEXPRESS2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Trend 2 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYTREND2LITE">
        <img src="images/Modeles/GALAXYTREND2LITE(G318H).png" alt="GALAXYTREND2LITE" />
        <sl-visually-hidden class="wf-name">GALAXYTREND2LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Trend Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYTRENDLITE">
        <img src="images/Modeles/GALAXYTRENDLITE.png" alt="GALAXYTRENDLITE" />
        <sl-visually-hidden class="wf-name">GALAXYTRENDLITE </sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Galaxy Trend </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYTREND">
        <img src="images/Modeles/GALAXYTREND.png" alt="GALAXYTREND" />
        <sl-visually-hidden class="wf-name">GALAXYTREND</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Ace 4 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYACE4LITE">
        <img src="images/Modeles/GALAXYACE4LITE.png" alt="GALAXYACE4LITE" />
        <sl-visually-hidden class="wf-name">GALAXYACE4LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Ace 4</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYACE4">
        <img src="images/Modeles/GALAXYACE4.png" alt="GALAXYACE4" />
        <sl-visually-hidden class="wf-name">GALAXYACE4</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Ace 3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYACE3">
        <img src="images/Modeles/GALAXYACE3.png" alt="GALAXYACE3" />
        <sl-visually-hidden class="wf-name">GALAXYACE3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Galaxy Ace 2</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYACE2">
        <img src="images/Modeles/GALAXYACE2.png" alt="GALAXYACE2" />
        <sl-visually-hidden class="wf-name">GALAXYACE2 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Galaxy Ace</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="GALAXYACE">
        <img src="images/Modeles/GALAXYACE.png" alt="GALAXYACE" />
        <sl-visually-hidden class="wf-name">GALAXYACE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Xcover 5</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="XCOVER%205S">
        <img src="images/Modeles/XCOVER%205S.png" alt="XCOVER%205S" />
        <sl-visually-hidden class="wf-name">XCOVER%205S </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Xcover 4S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="XCOVER4S">
        <img src="images/Modeles/XCOVER4S.png" alt="XCOVER4S" />
        <sl-visually-hidden class="wf-name">XCOVER4S </sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Xcover 4</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="XCOVER4">
        <img src="images/Modeles/XCOVER4.png" alt="XCOVER4" />
        <sl-visually-hidden class="wf-name">XCOVER4 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Xcover 3</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-samsung" aria-label="XCOVER3">
        <img src="images/Modeles/XCOVER3.png" alt="XCOVER3" />
        <sl-visually-hidden class="wf-name">XCOVER3 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

</div>




      


 <Footer />
    </Fragment>
   

  );
}

export default Panne1;