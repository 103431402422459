import React, { Fragment } from 'react';
import Slide from './slider';
import Footer from './footer';
import Header from './header';




function Honor() {
  return (
    <Fragment>


<Header />

<Slide /> <br/><br/><br/>



<section className="container mt-3">
      <div className="wf-step" aria-label="stepper" role="navigation">
        <a className="wf-step__item wf-step__item--hover " href="/reparations" aria-label="Choix appareil">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
        </a>
        <a className="wf-step__item wf-step__item--ahover" href="/smartphone" aria-label="Marque">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page" style={{ color: "black" }}>Marque</div>
        </a>
        <a className="wf-step__item wf-step__item--active" aria-label="Modèle">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Modèle</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label=">Panne">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Panne</div>
        </a>
        <a className="wf-step__item  wf-step__item--hover" aria-label="BCoordonnées">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
        </a>
      </div>
    </section>

    <div id="md-10" class="wf-grid wf-grid--devices wf-mt--4 wf-mb--3 mt-5">
  <div class="wf-card-container">
    <h5>Honor 50</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR50">
        <img src="images/Modeles/HONOR50.png" alt="HONOR50" />
        <sl-visually-hidden class="wf-name">HONOR50</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Honor 20 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR20PRO">
        <img src="images/Modeles/HONOR20PRO.png" alt="HONOR20PRO" />
        <sl-visually-hidden class="wf-name">HONOR20PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>  Honor 20 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR20LITE">
        <img src="images/Modeles/HONOR20LITE.png" alt="HONOR20LITE" />
        <sl-visually-hidden class="wf-name">HONOR20LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 20 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR20">
        <img src="images/Modeles/HONOR20.png" alt="HONOR20" />
        <sl-visually-hidden class="wf-name">HONOR20</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 10 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR10LITE">
        <img src="images/Modeles/HONOR10LITE.png" alt="HONOR10LITE" />
        <sl-visually-hidden class="wf-name">HONOR10LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 10</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR10">
        <img src="images/Modeles/HONOR10.png" alt="HONOR10" />
        <sl-visually-hidden class="wf-name">HONOR10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 9X Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR9XLITE">
        <img src="images/Modeles/HONOR9XLITE.png" alt="HONOR9XLITE" />
        <sl-visually-hidden class="wf-name">HONOR9XLITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 9X</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR9X">
        <img src="images/Modeles/HONOR9X.png" alt="HONOR9X" />
        <sl-visually-hidden class="wf-name">HONOR9X</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 9 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR9LITE">
        <img src="images/Modeles/HONOR9LITE.png" alt="HONOR9LITE" />
        <sl-visually-hidden class="wf-name">HONOR9LITE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 9</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR9">
        <img src="images/Modeles/HONOR9.png" alt="HONOR9" />
        <sl-visually-hidden class="wf-name">HONOR9</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor X8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONORX8">
        <img src="images/Modeles/HONORX8.png" alt="HONORX8" />
        <sl-visually-hidden class="wf-name">HONORX8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 8X</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR8X">
        <img src="images/Modeles/HONOR8X.png" alt="HONOR8X" />
        <sl-visually-hidden class="wf-name">HONOR8X</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 8A</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR8A">
        <img src="images/Modeles/HONOR8A.png" alt="HONOR8A" />
        <sl-visually-hidden class="wf-name">HONOR8A</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 8 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR8PRO">
        <img src="images/Modeles/HONOR8PRO.png" alt="HONOR8PRO" />
        <sl-visually-hidden class="wf-name">HONOR8PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR8">
        <img src="images/Modeles/HONOR8.png" alt="HONOR8" />
        <sl-visually-hidden class="wf-name">HONOR8</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 7X</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR7X">
        <img src="images/Modeles/HONOR7X.png" alt="iHONOR7Xphone" />
        <sl-visually-hidden class="wf-name">HONOR7X</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 7S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR7S">
        <img src="images/Modeles/HONOR7S.png" alt="HONOR7S" />
        <sl-visually-hidden class="wf-name">HONOR7S</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 7C</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR7C">
        <img src="images/Modeles/HONOR7C.png" alt="HONOR7C" />
        <sl-visually-hidden class="wf-name">HONOR7C</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 7A </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="iphone">
        <img src="images/Modeles/HONOR7A.png" alt="HONOR7A" />
        <sl-visually-hidden class="wf-name">HONOR7A</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 7</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR7">
        <img src="images/Modeles/HONOR7.png" alt="HONOR7" />
        <sl-visually-hidden class="wf-name">HONOR7</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 6X</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR6X">
        <img src="images/Modeles/HONOR6X.png" alt="HONOR6X" />
        <sl-visually-hidden class="wf-name">HONOR6X</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 6C Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR6CPRO">
        <img src="images/Modeles/HONOR6CPRO.png" alt="HONOR6CPRO" />
        <sl-visually-hidden class="wf-name">HONOR6CPRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 6C</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR6C">
        <img src="images/Modeles/HONOR6C.png" alt="HONOR6C" />
        <sl-visually-hidden class="wf-name">HONOR6C</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 6A</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR6A">
        <img src="images/Modeles/HONOR6A.png" alt="HONOR6A" />
        <sl-visually-hidden class="wf-name">HONOR6A</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 5X</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR5X">
        <img src="images/Modeles/HONOR5X.png" alt="HONOR5X" />
        <sl-visually-hidden class="wf-name">HONOR5X</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 5C</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR5C">
        <img src="images/Modeles/HONOR5C.png" alt="HONOR5C" />
        <sl-visually-hidden class="wf-name">HONOR5C</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor View 20</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="VIEW20">
        <img src="images/Modeles/VIEW20.png" alt="VIEW20" />
        <sl-visually-hidden class="wf-name">VIEW20</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor View 10</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONORVIEW10">
        <img src="images/Modeles/HONORVIEW10.png" alt="HONORVIEW10" />
        <sl-visually-hidden class="wf-name">HONORVIEW10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

 

  <div class="wf-card-container">
    <h5>Honor Play</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONORPLAY">
        <img src="images/Modeles/HONORPLAY.png" alt="HONORPLAY" />
        <sl-visually-hidden class="wf-name">HONORPLAY</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Honor 50 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR50LITE">
        <img src="images/Modeles/HONOR50LITE.png" alt="HONOR50LITE" />
        <sl-visually-hidden class="wf-name">HONOR50LITE </sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  
  <div class="wf-card-container">
    <h5>Magic 4 Lite</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="MAGIC4LITE">
        <img src="images/Modeles/MAGIC4LITE.png" alt="MAGIC4LITE" />
        <sl-visually-hidden class="wf-name">MAGIC4LITE </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Honor 10 Phanthom</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR10PHANTOM">
        <img src="images/Modeles/HONOR%2010%20Phantom.png" alt="HONOR10PHANTOM" />
        <sl-visually-hidden class="wf-name">HONOR10PHANTOM</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Honor 10X</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR10X">
        <img src="images/Modeles/HONOR%2010X.png" alt="HONOR10X" />
        <sl-visually-hidden class="wf-name">HONOR10X 5s</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Honor 9X Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONOR9XPRO">
        <img src="images/Modeles/HONOR%209X%20PRO%20.png" alt="HONOR9XPRO" />
        <sl-visually-hidden class="wf-name">HONOR9XPRO </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  
  <div class="wf-card-container">
    <h5>Honor X7</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="HONORX7">
        <img src="images/Modeles/IPHONE5S.png" alt="HONORX7" />
        <sl-visually-hidden class="wf-name">HONORX7 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Magic 4 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-honor" aria-label="MAGIC4PRO">
        <img src="images/Modeles/MAGIC%204%20PRO%20.png" alt="MAGIC4PRO " />
        <sl-visually-hidden class="wf-name">MAGIC4PRO</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

</div>











      


 <Footer />
    </Fragment>
   

  );
}

export default Honor;