import React, { Fragment } from 'react';
import Slide from './slider';
import Footer from './footer';
import Header from './header';




function Apple() {
  return (
    <Fragment>


<Header />



<Slide /> <br/><br/><br/>




<section className="container mt-3">
      <div className="wf-step" aria-label="stepper" role="navigation">
        <a className="wf-step__item wf-step__item--hover " href="/reparations" aria-label="Choix appareil">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
        </a>
        <a className="wf-step__item wf-step__item--ahover" href="/smartphone" aria-label="Marque">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page" style={{ color: "black" }}>Marque</div>
        </a>
        <a className="wf-step__item wf-step__item--active" aria-label="Modèle">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Modèle</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label=">Panne">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Panne</div>
        </a>
        <a className="wf-step__item  wf-step__item--hover" aria-label="BCoordonnées">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
        </a>
      </div>
    </section>

    <div id="md-10" class="wf-grid wf-grid--devices wf-mt--4 wf-mb--3 mt-5">
  <div class="wf-card-container">
    <h5>iPhone 14 pro max</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone14promax">
        <img src="images/Modeles/iphone14promax.png" alt="iphone14promax" />
        <sl-visually-hidden class="wf-name">iphone14promax</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>iPhone 14 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/iphone14pro.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 14 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE14PLUS.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 14</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/iphone14.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 13 Pro Max</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE13PRO MAX.webp" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 13 Pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE 13 PRO.webp" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 13</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE 13.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 13 Mini</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE 13 MINI.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone SE (2022)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/ISE3.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 12 pro max</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE12PROMAX.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 12 pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE12PRO.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 12</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE12.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 12 Mini</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE12MINI.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 11 pro max</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE11PROMAX.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 11 pro</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE11PRO.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 11</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE11.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone SE (2020)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONESE2020.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone XS MAX</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONEXSMAX.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone XS </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONEXS.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone XR</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONEXR.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone X</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONEX.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 8 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE8.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 8</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE8.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 7 plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE7PLUS.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 7</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE7.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 6S Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE6PLUS.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 6S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE6S.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 6 Plus</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE6PLUS.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 6</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE6.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone SE</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONESE.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPhone 5S</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple" aria-label="iphone">
        <img src="images/Modeles/IPHONE5S.png" alt="iphone" />
        <sl-visually-hidden class="wf-name">iphone 5s</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

</div>











      


 <Footer />
    </Fragment>
   

  );
}

export default Apple;