import React, { Fragment } from 'react';

import Footer from './footer';
import Header from './header';
import Slide from './slider';



function Apple1() {
  return (
    <Fragment>


<Header />

<Slide /> <br/><br/><br/>




<section className="container mt-3">
      <div className="wf-step" aria-label="stepper" role="navigation">
        <a className="wf-step__item wf-step__item--hover " href="/reparations" aria-label="Choix appareil">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
        </a>
        <a className="wf-step__item wf-step__item--ahover" href="/tablette" aria-label="Marque">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page" style={{ color: "black" }}>Marque</div>
        </a>
        <a className="wf-step__item wf-step__item--active" aria-label="Modèle">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Modèle</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label=">Panne">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Panne</div>
        </a>
        <a className="wf-step__item  wf-step__item--hover" aria-label="BCoordonnées">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
        </a>
      </div>
    </section>

    <div id="md-10" class="wf-grid wf-grid--devices wf-mt--4 wf-mb--3 mt-5">
  <div class="wf-card-container">
    <h5>iPad (9e génération)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="ip9ege">
        <img src="images/Modeles/ip9ege.png" alt="ip9ege" />
        <sl-visually-hidden class="wf-name">ip9ege</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>iPad (8e génération)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="iP8eg">
        <img src="images/Modeles/iP8eg.png" alt="iP8eg" />
        <sl-visually-hidden class="wf-name">iP8eg</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (7e génération)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="iP7eg">
        <img src="images/Modeles/IPAD20197E.png" alt="iP7eg" />
        <sl-visually-hidden class="wf-name">iP7eg</sl-visually-hidden>
      </a>
    </sl-card>
  </div>



  <div class="wf-card-container">
    <h5>iPad (6e génération)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPAD2018EG">
        <img src="images/Modeles/IPAD2018EG.png" alt="IPAD2018EG" />
        <sl-visually-hidden class="wf-name">IPAD2018EG</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (5e génération) </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="ipad2017">
        <img src="images/Modeles/ipad2017.png" alt="ipad2017" />
        <sl-visually-hidden class="wf-name">ipad2017</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (3e génération 11) </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADPRO(11)">
        <img src="images/Modeles/IPADPRO(11).png" alt="IPADPRO(11)" />
        <sl-visually-hidden class="wf-name">IPADPRO(11)</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad  (4e génération)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADPRO4">
        <img src="images/Modeles/IPADPRO4.png" alt="IPADPRO4" />
        <sl-visually-hidden class="wf-name">IPADPRO4</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (2e génération) </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADPRO2">
        <img src="images/Modeles/IPADPRO2.png" alt="IPADPRO2" />
        <sl-visually-hidden class="wf-name">IPADPRO2</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (3e génération)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADPRO12">
        <img src="images/Modeles/IPADPRO12.png" alt="IPADPRO12" />
        <sl-visually-hidden class="wf-name">IPADPRO12</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (1e génération) </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADPRO1EGE">
        <img src="images/Modeles/IPAD%20PRO%201EGE.png" alt="IPADPRO1EGE" />
        <sl-visually-hidden class="wf-name">IPADPRO1EGE</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (2e génération)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADPRO129">
        <img src="images/Modeles/IPADPRO12.9(2).png" alt="IPADPRO129" />
        <sl-visually-hidden class="wf-name">IPADPRO129</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (10,5)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADPRO105">
        <img src="images/Modeles/IPADPRO10.5.png" alt="IPADPRO105" />
        <sl-visually-hidden class="wf-name">IPADPRO105</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (1e génération)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADPRO19">
        <img src="images/Modeles/IPADPRO12.9.png" alt="IPADPRO129" />
        <sl-visually-hidden class="wf-name">IPADPRO129</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad (9,7)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADPRO97">
        <img src="images/Modeles/IPADPRO9.7.png" alt="IPADPRO7" />
        <sl-visually-hidden class="wf-name">IPADPRO97</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad Air 5</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="ipadair5">
        <img src="images/Modeles/ipadair5.png" alt="ipadair5" />
        <sl-visually-hidden class="wf-name">ipadair5</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad Air 4 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="ipadair4">
        <img src="images/Modeles/ipadair4.png" alt="ipadair4" />
        <sl-visually-hidden class="wf-name">ipadair4</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad Air 3 </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADAIR3">
        <img src="images/Modeles/IPADAIR3.png" alt="IPADAIR3" />
        <sl-visually-hidden class="wf-name">IPADAIR3</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad Mini 6</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADMINI6">
        <img src="images/Modeles/IPADMINI6.png" alt="IPADMINI6" />
        <sl-visually-hidden class="wf-name">IPADMINI6</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad Mini 5</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADMINI5">
        <img src="images/Modeles/IPADMINI5.png" alt="IPADMINI5" />
        <sl-visually-hidden class="wf-name">IPADMINI5</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>iPad Mini 4</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-apple-1" aria-label="IPADMINI4">
        <img src="images/Modeles/IPADMINI4.png" alt="IPADMINI4" />
        <sl-visually-hidden class="wf-name">IPADMINI4 </sl-visually-hidden>
      </a>
    </sl-card>
  </div>

</div>











      


 <Footer />
    </Fragment>
   

  );
}

export default Apple1;