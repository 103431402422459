import React, { Fragment } from 'react';



function Header() {
  return (
    <Fragment>
        <header class="navbar-sticky bg-light"><nav class="navbar navbar-expand-lg navbar-light"><div class="container-fluid">
    <button class="navbar-toggler order-3" type="button" data-bs-toggle="modal" data-bs-target="#navigation" aria-label="Ouvrir le menu">
        <span class="navbar-toggler-icon"></span></button><a class="navbar-brand order-2 order-lg-1" href="/" aria-label="Retourner à l'accueil">
           
        <img src="assets/img/osmart 1 (1).svg" alt="" srcset="" />


            </a><div class="collapse navbar-collapse order-lg-2">
            <ul class="navbar-nav ms-auto" role="list">
            <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="/" aria-label="Réparez dés maintenant votre appareil"><span class="fw-medium">Accueil</span></a>
            </li>
            <li class="nav-item"><a class="nav-link" href="/reparations" aria-label="Découvrez les accessoires WeFix"><span class="fw-medium">Réparation</span></a></li>
            <li class="nav-item"><a class="nav-link" href="/contact" aria-label="Toutes nos boutiques WeFix"><span class="fw-medium">Contact</span></a></li>
           </ul>
           </div></div></nav></header>


           <div class="wf-aside modal" tabindex="-1" id="navigation" role="dialog" x-data="{ reparation: false, protection: false }"><div class="wf-aside__wrap modal-dialog modal-dialog-scrollable modal-fullscreen" role="document">
                <div class="wf-aside__screen modal-content" x-show="!reparation &amp;&amp; !protection" x-cloak><div class="wf-aside__screen-nav">
                    <div class="wf-aside__screen-nav--title">Menu</div>
                    <div class="wf-aside__screen-nav-close" data-bs-dismiss="modal" aria-label="Fermer le menu"><i class="ci-close"></i></div></div>
          <a href="/"> <div class="wf-aside__protection-content-card-body--title">Accueil</div></a> 
          <a href="/reparations"> <div class="wf-aside__protection-content-card-body--title">Réparation</div></a>
            <a href="/contact"> <div class="wf-aside__protection-content-card-body--title">Contact</div></a>
        </div>
     </div></div>








     
    </Fragment>


  );
}

export default Header;
