import React, { Fragment } from 'react';
import Slide from './slider';
import Footer from './footer';
import Header from './header';




function Acer() {
  return (
    <Fragment>


<Header />



<Slide /> <br/><br/><br/>



<section className="container mt-3">
      <div className="wf-step" aria-label="stepper" role="navigation">
        <a className="wf-step__item wf-step__item--hover " href="/reparations" aria-label="Choix appareil">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
        </a>
        <a className="wf-step__item wf-step__item--ahover" href="/tablette" aria-label="Marque">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page" style={{ color: "black" }}>Marque</div>
        </a>
        <a className="wf-step__item wf-step__item--active" aria-label="Modèle">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Modèle</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label=">Panne">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Panne</div>
        </a>
        <a className="wf-step__item  wf-step__item--hover" aria-label="BCoordonnées">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
        </a>
      </div>
    </section>

    <div id="md-10" class="wf-grid wf-grid--devices wf-mt--4 wf-mb--3 mt-5">
  <div class="wf-card-container">
    <h5>Iconia Tab ( A510)</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-acer" aria-label="ICONIATABA510">
        <img src="images/Modeles/ICONIATABA510.png" alt="ICONIATABA510" />
        <sl-visually-hidden class="wf-name">ICONIATABA510</sl-visually-hidden>
      </a>
    </sl-card>
  </div>
  <div class="wf-card-container">
    <h5>Iconia Tab </h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-acer" aria-label="ICONIATAB">
        <img src="images/Modeles/ICONIATAB.png" alt="ICONIATAB" />
        <sl-visually-hidden class="wf-name">ICONIATAB</sl-visually-hidden>
      </a>
    </sl-card>
  </div>

  <div class="wf-card-container">
    <h5>Iconia A3-A10</h5>
    <sl-card class="wf-card wf-card--brand">
      <a href="/panne-acer" aria-label="ICONIAA3-A10">
        <img src="images/Modeles/ICONIAA3-A10.png" alt="ICONIAA3-A10" />
        <sl-visually-hidden class="wf-name">ICONIAA3-A10</sl-visually-hidden>
      </a>
    </sl-card>
  </div>



</div>











      


 <Footer />
    </Fragment>
   

  );
}

export default Acer;