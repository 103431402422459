import React from 'react';
import './App.css';
import {BrowserRouter as Router, Routes , Route} from 'react-router-dom';
import Index from './pages/index';
import Slide from './pages/slider';
import Reparation from './pages/reparations';
import Smartphones from './pages/smartphone';
import Tablette from './pages/tablette';
import Console from './pages/console';

import Apple from './pages/apple';
import Samsung from './pages/samsung';
import Huawei from './pages/huawei';
import Honor from './pages/honor';
import Xiaomi from './pages/xiaomi';
import Alcatel from './pages/alcatel';


import Apple1 from './pages/apple-1'
import Huawei1 from './pages/huawei-1';
import Acer from './pages/acer';
import Asus from './pages/asus';
import Sony from './pages/sony';


import Nintendo from './pages/nintendo';
import Pannenintendo from './pages/panne-nintendo';


import Panne from './pages/panne-apple';
import Panne1 from './pages/panne-samsung';
import Panne2 from './pages/panne-huawei';
import Panne3 from './pages/panne-honor';
import Panne4 from './pages/panne-xiaomi';
import Panne5 from './pages/panne-alcatel';

import Panne6 from './pages/panne-apple-1'
import Panne7 from './pages/panne-huawei-1';
import Panne8 from './pages/panne-acer';
import Panne9 from './pages/panne-asus';
import Panne10 from './pages/panne-sony';


import Rendezvous from './pages/rendez-vous';
import Contact from './pages/contact';











function App() {
  return (

<div className="App">
<Router>
<Routes>
<Route path='/' element={<Index />} />
<Route path='/slider' element={<Slide/>} />
<Route path='/reparations' element={<Reparation />} />
<Route path='/smartphone' element={<Smartphones />} />
<Route path='/tablette' element={<Tablette/>} />
<Route path='/console' element={<Console/>} />


<Route path='/apple' element={<Apple/>} />
<Route path='/samsung' element={<Samsung/>} />
<Route path='/huawei' element={<Huawei/>} />
<Route path='/honor' element={<Honor/>} />
<Route path='/xiaomi' element={<Xiaomi/>} />
<Route path='/alcatel' element={<Alcatel/>} />


<Route path='/apple-1' element={<Apple1/>} />
<Route path='/huawei-1' element={<Huawei1/>} />
<Route path='/acer' element={<Acer/>} />
<Route path='/asus' element={<Asus/>} />
<Route path='/sony' element={<Sony/>} />

<Route path='/nintendo' element={<Nintendo/>} />
<Route path='/panne-nintendo' element={<Pannenintendo/>} />


<Route path='/panne-apple' element={<Panne/>} />
<Route path='/panne-samsung' element={<Panne1/>} />
<Route path='/panne-huawei' element={<Panne2/>} />
<Route path='/panne-honor' element={<Panne3/>} />
<Route path='/panne-xiaomi' element={<Panne4/>} />
<Route path='/panne-alcatel' element={<Panne5/>} />
<Route path='/panne-apple-1' element={<Panne6/>} />

<Route path='/panne-huawei-1' element={<Panne7/>} />
<Route path='/panne-acer' element={<Panne8/>} />
<Route path='/panne-asus' element={<Panne9/>} />
<Route path='/panne-sony' element={<Panne10/>} />




<Route path='/rendez-vous' element={<Rendezvous />} />
<Route path='/contact' element={<Contact/>} />










</Routes>
  
</Router>
          
</div>


 
  );
}

export default App;