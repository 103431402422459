import React, { Fragment } from 'react';


function Footer() {
  return (
    <Fragment>

<footer class="footer">
    <div class="start-learning">
        <div class="footer-start">
            <div class="texts">
                <h2 class="section-title">Ne ratez jamais nos nouvelles</h2>
              
            </div>
            <br />
                <div>
                    <form class="form-newsletter subscription-form validate" novalidate><div class="input-group flex-nowrap"><input class="form-control form-control-lg rounded-start" type="email" data-newsletter-mail placeholder="Adresse mail..." required /><button class="btn cta-primary fs-14" type="submit" aria-label="Abonnez-vous à notre newsletter">VALIDER </button></div></form>
                </div>
           
        </div>
    </div>
    <div class="inner">
        <div class="column is-logo">
            <a href="/" class="main-logo">
                <div class="logo">
                   <img src="assets/img/osmart 1.svg" />
                </div>
              
               
            </a>
            <br />  


            <span> <a href="https://www.instagram.com/osmart72/" target="_blank"> <img src="assets/img/instagram.svg"  width="8%" style={{marginTop:'4%'}}/></a></span>
            <span> <a href="https://www.snapchat.com/add/o-smart72" target="_blank"> <img src="assets/img/snap.svg"  width="8%" style={{marginTop:'4%',marginLeft:'4%'}}/></a></span>
        </div>
      
                   
            
    
        <div class="column is-navigation">
            <div class="column-title">Menu</div>
            <ul>
                <li><a href="/">Accueil</a></li>
                <li><a href="/reparations">Réparation</a></li>
                <li><a href="/contact">Contact</a></li>
            </ul>
        </div>
        <div class="column is-navigation">
            <div class="column-title">Infos</div>
            <ul>
                <li><i class="fa fa-envelope-open"></i> 29 rue des coquelicots ,72700, Allonnes</li>
                <li><a href="mailto:contact@osmart.fr" ><i class="fa fa-twitter"></i> contact@osmart.fr</a></li>
                <li><i class="fa fa-linkedin"></i> Horaires : 7/7  / 14H-20H</li>
                <li><a href="tel:+33767665209" ><i class="fa fa-linkedin"></i> +33 7 67 66 52 09</a></li>
            </ul>
           
        </div>
        
     


    </div>
    <div class="separateur-blanc"></div>



    <div style={{ color: "white",fontFamily:'josefin sans',fontSize:"22px",marginLeft:"10%",marginTop:"3%"}}>Réaliser par : </div>
    <div style={{ marginRight:"10%",float:"right",marginTop:"-2%"}}> <a href="https://sceniusdigi.com/" target="_blank"> <img src="assets/img/digi.svg"  width="120%" /></a> </div>
</footer>

    </Fragment>


   

    );
  }
  
  export default Footer;