import React, { Fragment } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function Slide() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
    };
  return (
    <Fragment>
    

       <section className="phone-slider">
  <Slider
    dots={false}
    infinite={true}
    speed={500}
    slidesToShow={1}
    slidesToScroll={1}
    arrows={true}
    autoplay={true}
    autoplaySpeed={2000}
  >
    <div>
      <img src="../images/Sans-titre---1.png" alt="Téléphone réparé 1" />
    </div>
    <div>
      <img src="../images/Sans-titre---2.png" alt="Téléphone réparé 2" />
    </div>
    <div>
      <img src="../images/Sans-titre---3.png" alt="Téléphone réparé 3" />
    </div>
    {/* Ajoutez plus d'images si nécessaire */}
  </Slider>
</section>

 
    </Fragment>


  );
}

export default Slide;
