import React, { Fragment , useState } from 'react';
import Slide from './slider';
import Footer from './footer';
import Header from './header';





function Reparation() {



  const [formData, setFormData] = useState({});

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await fetch("http://localhost:3001/sendmail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    if (response.status === 200) {
      alert("Email de confirmation envoyé avec succès !");
    } else {
      alert("Erreur lors de l'envoi de l'email de confirmation.");
    }
    
  }
  
  
  ;
  






  return (
    <Fragment>
       
       <Header />
  




       <Slide /> <br/><br/><br/>




<section className="container mt-3">
      <div className="wf-step" aria-label="stepper" role="navigation">
        <a className="wf-step__item wf-step__item--hover " href="/reparations" aria-label="Choix appareil">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
        </a>
        <a className="wf-step__item wf-step__item--hover"  aria-label="Marque">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Marque</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label="Modèle<">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Modèle</div>
        </a>
        <a className="wf-step__item wf-step__item--hover" aria-label=">Panne">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Panne</div>
        </a>
        <a className="wf-step__item  wf-step__item--active" aria-label="Coordonnées">
          <div className="wf-step__item--dot">
            <sl-divider className="wf-divider"></sl-divider>
            <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
            <sl-divider className="wf-divider"></sl-divider>
          </div>
          <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
        </a>
      </div>
    </section>


<br/>


    <div className="bg-form mt-5" id="contact-rendez-vous" style={{}}>
      <form  className="form wf-form-client-reparation mt-4"
  method="POST"
  onSubmit={handleSubmit}
  Validate>
 
         <h1>Prendre un rendez-vous</h1>
         <br/><br/>
        <div className="grid" style={{'--wf-gap': '16px'}}>
          <div className="d-flex g-col-sm-6 g-col-12">
            <input className="btn-check" type="radio" id="MR" name="civility" value="MR" checked />
            <label className="btn btn-outline-primary fw-medium w-50 me-2" htmlFor="MR">Monsieur</label>
            <input className="btn-check" type="radio" id="MME" name="civility" value="MME" />
            <label className="btn btn-outline-primary fw-medium w-50" htmlFor="MME">Madame</label>
          </div>
        </div>
        <div className="grid mt-3" style={{'--wf-gap': '16px'}}>
          <div className="g-col-sm-6 g-col-12">
            <div className="form-floating">
            <input className="form-control" type="text" name="surname" id="surname" style={{textTransform: 'capitalize'}} placeholder="..." maxLength="40" autoFocus autoComplete required />

              <label className="fs-sm mts" htmlFor="surname">Votre prénom</label>
              <div className="invalid-tooltip fs-12 fw-medium fade-in mt-1">Veuillez entrer votre prénom !</div>
            </div>
          </div>
          <div className="g-col-sm-6 g-col-12">
            <div className="form-floating">
              <input className="form-control" type="text" name="surname" id="name" style={{textTransform: 'capitalize'}} placeholder="..." maxLength="40" onChange={handleInputChange}  autoComplete required />
              <label className="fs-sm mts" htmlFor="name">Votre nom</label>
              <div className="invalid-tooltip fs-12 fw-medium fade-in mt-1">Veuillez entrer votre nom !</div>
            </div>
          </div>
          <div className="g-col-12">
            <div className="form-floating">
              <input className="form-control" type="email" name="mail" id="mail" placeholder="..." maxLength="60" onChange={handleInputChange} autoComplete required />
              <label className="fs-sm mts" htmlFor="mail">Votre adresse mail</label>
              <div className="invalid-tooltip fs-12 fw-medium fade-in mt-1">Adresse mail incorrecte !</div>
            </div>
          </div>
          
          
         

        <div className="g-col-sm-6 g-col-12">
          <div className="form-floating">
            <input className="form-control" type="number" name="postal" id="postal" placeholder="..." inputMode="numeric" onInput={(e) => { e.target.value = e.target.value.replace(/\D+/g, '') }} onChange={handleInputChange} required />
            <label className="fs-sm mts" htmlFor="postal">Votre code postal</label>
            <div className="invalid-tooltip fs-12 fw-medium fade-in mt-1">Veuillez entrer votre code postal !</div>
          </div>
        </div>
        <div className="g-col-sm-6 g-col-12">
          <div className="form-floating">
            <select className="form-select" name="country" id="country">
              <option value="France" selected>France</option>
              <option value="Belgique">Belgique</option>
            </select>
            <label htmlFor="country">Pays<span className="ms-1" data-form-country-flag></span></label>
          </div>
        </div>
        <div className="g-col-12">
          <div className="form-floating">
            <input className="form-control" type="number" name="phone" id="phone" placeholder="..." inputMode="numeric" onInput={(e) => { e.target.value = e.target.value.replace(/\D+/g, '') }} onChange={handleInputChange}   autoComplete="required" required />
            <label className="fs-sm mts" htmlFor="phone">Numéro de téléphone</label>
            <div className="invalid-tooltip fs-12 fw-medium fade-in mt-1">Veuillez entrer votre numéro de téléphone !</div>
          </div>
        </div>

        <div className="g-col-12 mt-3">
        <div className="form-check">
          <input type="checkbox" className="form-check-input" id="validationCheck" onChange={handleInputChange} required />
          <label className="form-check-label" htmlFor="validationCheck">Je ne souhaite pas recevoir des informations commerciales, des nouveautés, des promotions et tous les bons plans et remises de la part de O’smart.</label>
        </div>
      </div>
      <div className="g-col-12 mt-3">
        <div className="g-recaptcha" data-sitekey="votre_clé_recaptcha"></div>
      </div>


        <div className="text-center mt-md-3 mt-5">
          <button className="btn btn-lg cta-primary" type="submit" data-event-type="rdv" data-label-form-submit size="large">Envoyer</button>
        </div>
    


   
          





 
          
          
          
          
          </div></form></div>







        <Footer />
    </Fragment>


  );
}

export default Reparation;
