import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Slide from './slider';
import Footer from './footer';
import Header from './header';

function Panne7() {
  const [description, setDescription] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Vérifiez si la description est vide
    if (description.trim() === '') {
      alert('Veuillez décrire votre panne avant de continuer.');
    } else {
      // Ici, vous pouvez envoyer la description à votre serveur ou la traiter comme vous le souhaitez
      navigate('/rendez-vous');
    }
  };
  
  return (
    <Fragment>
  
  <Header />
  <Slide /> <br/><br/><br/>


<div class="none" id="spin-data" data-category="Réparer"></div>
<section class="banner-reparation mt-md-2" role="banner"><div class="body"><div class="image-overlay"></div><h1 class="title-banner-pages"> Réparation</h1></div></section>
<section class="mt-md-5 mt-4"><div class="container"><div class="wf-speech-bubble"></div></div></section>

<section className="container mt-3">
 <div className="wf-step" aria-label="stepper" role="navigation">
   <a className="wf-step__item wf-step__item--hover " href="/reparations" aria-label="Choix appareil">
     <div className="wf-step__item--dot">
       <sl-divider className="wf-divider"></sl-divider>
       <sl-button className="wf-button" variant="default" circle="circle">1</sl-button>
       <sl-divider className="wf-divider"></sl-divider>
     </div>
     <div className="wf-step__item--text" aria-current="page">Choix appareil</div>
   </a>
   <a className="wf-step__item wf-step__item--hover" href="/tablette" aria-label="Marque">
     <div className="wf-step__item--dot">
       <sl-divider className="wf-divider"></sl-divider>
       <sl-button className="wf-button" variant="default" circle="circle">2</sl-button>
       <sl-divider className="wf-divider"></sl-divider>
     </div>
     <div className="wf-step__item--text" aria-current="page">Marque</div>
   </a>
   <a className="wf-step__item wf-step__item--hover" href="/huawei-1" aria-label="Modèle">
     <div className="wf-step__item--dot">
       <sl-divider className="wf-divider"></sl-divider>
       <sl-button className="wf-button" variant="default" circle="circle">3</sl-button>
       <sl-divider className="wf-divider"></sl-divider>
     </div>
     <div className="wf-step__item--text" aria-current="page">Modèle</div>
   </a>
   <a className="wf-step__item wf-step__item--active" aria-label="Panne">
     <div className="wf-step__item--dot">
       <sl-divider className="wf-divider"></sl-divider>
       <sl-button className="wf-button" variant="default" circle="circle">4</sl-button>
       <sl-divider className="wf-divider"></sl-divider>
     </div>
     <div className="wf-step__item--text" aria-current="page">Panne</div>
   </a>
   <a className="wf-step__item  wf-step__item--hover" aria-label="BCoordonnées">
     <div className="wf-step__item--dot">
       <sl-divider className="wf-divider"></sl-divider>
       <sl-button className="wf-button" variant="default" circle="circle">5</sl-button>
       <sl-divider className="wf-divider"></sl-divider>
     </div>
     <div className="wf-step__item--text" aria-current="page">Coordonnées</div>
   </a>
 </div>
</section>

<br/><br/>
<section className="container mt-3">
        <form onSubmit={handleSubmit} style={{ paddingRight: "10%",paddingLeft:"10%"}}>
          <div className="form-group">
          <h1> <label htmlFor="description">Décrivez-nous votre panne</label></h1><br/> 
            <textarea
              className="form-control"
              id="description"
              rows="3"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              style={{height:"300px"}}
            ></textarea>
          </div><br />
          <button type="submit" className="btn btn-lg cta-primary"  autoComplete="required" required >
            Envoyer
          </button>
        </form>
      </section>


        
    
      

                    <Footer />
                    </Fragment>
                    );
                    }
                    export default Panne7;
