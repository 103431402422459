import React, { Fragment } from 'react';
import Footer from './footer';
import Header from './header';



function Contact() {
  return (
    <Fragment>
       <Header />



     <div class="none" id="spin-data" data-category="Réparer"></div>
<section class="banner-reparation mt-md-2" role="banner"><div class="body"><div class="image-overlay"></div><h1 class="title-banner-pages"> Contactez-nous</h1></div></section>
<section class="mt-md-5 mt-4"><div class="container"><div class="wf-speech-bubble"></div></div></section>



<section class="container" id="contact-info"><div class="grid " style={{
        display: "grid",
        gridTemplateRows: `repeat(var(--wf-rows, 1), 1fr)`,
        gridTemplateColumns: `repeat(var(--wf-columns, 9), 1fr)`,
        gap: `var(--wf-gap, -0.125rem)`,"--wf-gap": "16px", marginTop: "3%",textAlign:"left"
      }}>
   
<div class="g-col md-4 g-col-3"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body" style={{textAlign:"left"}}><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/phone-call.svg" alt="" srcset="" style={{width:"87px"}}/>
        <img src="assets/img/phone-call 2.svg" alt="" srcset="" style={{position:"absolute",maxWidth:"max-content"}}/>
      </div></div><div class="title-subsection mt-1">Téléphone</div><p class="mt-3">+33 7 67 66 52 09
       
        </p></div></div></div>



      <div class="g-col md-4 g-col-3"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body" style={{textAlign:"left"}}><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/mail.svg" alt="" srcset=""  style={{width:"87px"}}/>
        <img src="assets/img/mail 2.svg" alt="" srcset="" style={{position:"absolute",maxWidth:"max-content"}}/>
      </div></div><div class="title-subsection mt-1">Email </div><p class="mt-3">contact@osmart.fr
       
        </p></div></div></div>

        <div class="g-col md-4 g-col-3"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body" style={{textAlign:"left"}}><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/location.svg" alt="" srcset="" style={{width:"87px"}} />
        <img src="assets/img/location 2.svg" alt="" srcset="" style={{position:"absolute",maxWidth:"max-content",marginTop:"-30px"}}/>
      </div></div><div class="title-subsection mt-1">Adresse </div><p class="mt-3">29 rue des coquelicots, 72700, Allonnes
       
        </p></div></div></div>


    



</div></section>



<section class="container" id="contact-info2"><div class="grid " style={{
        display: "grid",
        gridTemplateRows: `repeat(var(--wf-rows, 1), 1fr)`,
        gridTemplateColumns: `repeat(var(--wf-columns, 9), 1fr)`,
        gap: `var(--wf-gap, -0.125rem)`,"--wf-gap": "16px", marginTop: "3%",textAlign:"left"
      }}>
   
<div class="g-col md-12 g-col-12"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body" style={{textAlign:"left"}}><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/phone-call.svg" alt="" srcset="" style={{width:"87px"}}/>
        <img src="assets/img/phone-call 2.svg" alt="" srcset="" style={{position:"absolute",maxWidth:"max-content"}}/>
      </div></div><div class="title-subsection mt-1">Téléphone</div><p class="mt-3">+33 7 67 66 52 09
       
        </p></div></div></div>



      <div class="g-col md-12 g-col-12"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body" style={{textAlign:"left"}}><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/mail.svg" alt="" srcset=""  style={{width:"87px"}}/>
        <img src="assets/img/mail 2.svg" alt="" srcset="" style={{position:"absolute",maxWidth:"max-content"}}/>
      </div></div><div class="title-subsection mt-1">Email </div><p class="mt-3">contact@osmart.fr
       
        </p></div></div></div>

        <div class="g-col md-12 g-col-12"><div class="card h-100 border-0 shadow pt-5 px-sm-3 px-md-0 px-lg-3 pb-sm-3 pb-md-0 pb-lg-3 ms-xl-2 text-center"><div class="card-body" style={{textAlign:"left"}}><div class="d-inline-block translate-middle-y "><div class="title-subsection text-light">
        <img src="assets/img/location.svg" alt="" srcset="" style={{width:"87px"}} />
        <img src="assets/img/location 2.svg" alt="" srcset="" style={{position:"absolute",maxWidth:"max-content",marginTop:"-30px"}}/>
      </div></div><div class="title-subsection mt-1">Adresse </div><p class="mt-3">29 rue des coquelicots, 72700, Allonnes
       
        </p></div></div></div>


    



</div></section>




    <div className="bg-form mt-5" style={{padding: '6% 0% 6% 0%', margin: '0% 7% 0 7%', boxShadow: 'rgb(54 188 217 / 25%) 0px 2px 8px 0px'}}>
      <form className="form wf-form-client-reparation mt-4" method="POST" Validate style={{maxWidth: '1000px'}}>
        <div className="grid" style={{'--wf-gap': '16px'}}>
          <div className="d-flex g-col-sm-12 g-col-12">
         <h1>Contactez-nous</h1>
          </div>
        </div>
        <div className="grid mt-3" style={{'--wf-gap': '16px'}}>
          <div className="g-col-sm-6 g-col-12">
            <div className="form-floating">
            <input className="form-control" type="text" name="surname" id="surname" style={{textTransform: 'capitalize'}} placeholder="..." maxLength="40" autoFocus autoComplete required />

              <label className="fs-sm mts" htmlFor="surname">Nom et  prénom</label>
              <div className="invalid-tooltip fs-12 fw-medium fade-in mt-1">Veuillez entrer votre prénom !</div>
            </div>
          </div>

          <div className=" g-col-sm-6 g-col-12">
            <div className="form-floating">
              <input className="form-control" type="email" name="mail" id="mail" placeholder="..." maxLength="60" autoComplete required />
              <label className="fs-sm mts" htmlFor="mail">Email</label>
              <div className="invalid-tooltip fs-12 fw-medium fade-in mt-1">Adresse mail incorrecte !</div>
            </div>
          </div>


          <div className=" g-col-12">
            <div className="form-floating">
              <input className="form-control" type="text" name="surname" id="sujet" style={{textTransform: 'capitalize'}} placeholder="..." maxLength="40" autoComplete required />
              <label className="fs-sm mts" htmlFor="sujet">Sujet</label>
              <div className="invalid-tooltip fs-12 fw-medium fade-in mt-1">Veuillez entrer votre Sujet !</div>
            </div>
          </div>
          
          
          
      
          <div className="g-col-12">
  <div className="form-floating">
    <textarea className="form-control" name="message" id="message" style={{ height: "172px" }} placeholder="..." onInput={(e) => { e.target.value = e.target.value.replace(/\D+/g, '') }} autoComplete="required" required></textarea>
    <label className="fs-sm mts" htmlFor="message">Message</label>
    <div className="invalid-tooltip fs-12 fw-medium fade-in mt-1">Veuillez entrer votre message !</div>
  </div>
</div>

      
      <div className="g-col-12 mt-3">
        <div className="g-recaptcha" data-sitekey="votre_clé_recaptcha"></div>
      </div>


        <div className="text-center mt-md-3 mt-5">
          <button className="btn btn-lg cta-primary" type="submit" data-event-type="rdv" data-label-form-submit size="large">Envoyer</button>

        </div>

          
          </div></form></div>







        <Footer />
    </Fragment>


  );
}

export default Contact;
